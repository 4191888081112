import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-grid-edit',
  templateUrl: './grid-edit.component.html',
  styleUrls: ['./grid-edit.component.scss']
})
export class GridEditComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  params: any;
  agInit(params: any){
    this.params = params; 
  } 
  btnClickedHandler(e) {
    this.params.clicked(this.params.value);
  }

}
