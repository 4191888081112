import { Pipe, PipeTransform } from '@angular/core';
import { IFile } from 'app/models/perizia.interface';

@Pipe({
  name: 'filterByFileType'
})
export class FilterByFileTypePipe implements PipeTransform {

  transform(items: IFile[], args: string[]): any {

   // console.log(items)

    items = items.filter(item => {

      if (!args || args.length == 0) return true;


      for (let i = 0; i < args.length; i++) {
        if (args[i].replace(this.fileType(item), '') !=args[i] )  return true;
      }

      //return args.includes(this.fileType(item));

      return false;

    });

    return items
  }


  fileType(f: IFile): string {

    //console.log(f)

    if (f.fileName && f.fileName.startsWith('SAM_100_')) return '360';
    if (f.fileName && f.fileName.startsWith('360')) return '360';

    if (f.fileName && f.fileName.toLowerCase().startsWith('pparcella')) return 'pp';


    let ext = '';
    try {
      ext = f.fileName.split('.').pop().toLocaleLowerCase();
    } catch (error) {

    }


    switch (ext) {
      case 'adz':
        ext = 'adz';
        break;

      case 'pdf':
        ext = 'pdf';
        break;

      case 'png':
      case 'jpg':
      case 'jpeg':
      case 'tiff':
      case 'tif':
      case 'gif':
        ext = 'img';
        break;

      default:
        ext = 'oth';
        break;
    }
    return ext;

  }

}
