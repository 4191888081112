import { Component, isDevMode} from '@angular/core';
import { MysqlService } from './services/mysql.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  favicon='';

  constructor(private db:MysqlService){

    this.favicon = db.getIcon();

/*
    if (!isDevMode() ){
      window.console.log = function () { }; 
      window.console.warn = function () { };
    }

    window.console.warn = function () { };
    */
  }
 

}
