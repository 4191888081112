
import { Component, OnInit, ElementRef, ViewChild, Input, AfterViewInit, HostListener, Output, EventEmitter } from '@angular/core';
import { IResult, IDamage } from '../../models/perizia.interface';

@Component({
  selector: 'app-blueprint',
  templateUrl: './blueprint.component.html',
  styleUrls: ['./blueprint.component.css']
})
export class BlueprintComponent implements OnInit, AfterViewInit {


  value: IResult
  selectedDamage: IDamage;

  bg;

  //class:string='';
  @Input() disabled: boolean;

  @Input() //value: IResult
  set setValue(value: IResult) {
    //this.getContext();
    console.log('prev value: ', this.value);
    console.log('got name: ', value);
    this.value = value;

    //this.class=`{'blueprint':true, '${this.value.vehType}'}`

    this.refresh()


  }


  @Output() onNew: EventEmitter<any> = new EventEmitter();
  @Output() onDelete: EventEmitter<void> = new EventEmitter();

  @Output() onSelected = new EventEmitter<any>();

  VIEW_LIST = 1;
  VIEW_GRAPH = 0;

  view = this.VIEW_GRAPH;

  displayedColumns: string[] = ['x', 'y', 'severity', 'type'];

  dot;


  settings = {
    actions: { position: 'right', edit: false, add: false },
    columns: {
      i: {
        title: 'N.', filter: false
      },
      xy: {
        title: 'Coordinate', filter: false
      },
      severity: {
        title: 'Gravità', filter: false
      },
      type: {
        title: 'Tipo', filter: false
      }
    }
  };

  //source: LocalDataSource;



  //damages: IDamage[] = [];
  canvasEl: HTMLCanvasElement;
  currentSeverity = 'L';

  @ViewChild('canvas') public canvas: ElementRef;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    console.log('refresh')
    this.refresh();
  }

  isMouseDown = false;

  // setting a width and height for the canvas
  //@Input() public width = 800;
  //@Input() public height = 600;

  width = 0;
  height = 0;

  private cx: CanvasRenderingContext2D;

  constructor() {


  }

  ngOnInit() {


    this.bg = new Image();
    this.bg.onload = () => {

      console.log('blueprint init')
      this.getContext();
      this.refresh();

    }

    this.bg.src = '../../../assets/img/blueprint/blueprint_' + this.value.vehType + '.png';

  }

  getContext() {
    console.log('get context')
    // get the context
    this.canvasEl = this.canvas.nativeElement;
    this.cx = this.canvasEl.getContext('2d');

    //this.setBg();
    // set the width and height
    this.updateSize();


    // set some default properties about the line
    this.cx.lineWidth = 1;
    this.cx.lineCap = 'round';
  }
  /*
    setDot() {
      this.dot = new Image();
      this.dot.src = '../../../assets/img/damage_dot.png';
    }
  */
  public ngAfterViewInit() {

    //this.setDot();

    this.getContext();



    this.refresh();
  }

  updateSize() {
    if (!this.canvasEl) {
      console.log('null ')
      return;
    }
    this.width = this.canvasEl.getBoundingClientRect().width;
    this.height = this.canvasEl.getBoundingClientRect().height;
    this.canvasEl.width = this.width;
    this.canvasEl.height = this.height;

    console.log(this.width + 'x' + this.width)

    //if (this.width + this.height == 0) this.updateSize();
  }

  private drawOnCanvas(d: IDamage, i) {
    // incase the context is not set
    if (!this.cx) {
      console.warn('not context');
      this.getContext();
      return;
    }


    if (!this.dot) {
      console.warn('not dot');
      //this.setDot();
    }


    this.cx.beginPath();

    let color;
    switch (d.severity) {
      case 'L': color = 'green'; break;
      case 'M': color = 'orange'; break;
      case 'G': color = 'red'; break;
      case 'S': color = '#0066ff'; break;
    }

    this.cx.strokeStyle = "#999";
    this.cx.fillStyle = color;



    let bgRatio = this.bg.width / this.bg.height;

    let x = d.x * this.width / 100;
    let y = d.y * this.width / bgRatio / 100;
    let r = 0.00625 * this.width * 3;


    //console.log(this.dot)

    //this.cx.drawImage(this.dot, x - r / 2, y - r / 2, r, r)

    this.cx.textAlign = "center";
    this.cx.font = 'bold ' + r + "px Courier";
    this.cx.arc(x, y, r / 2, 0, 2 * Math.PI);
    this.cx.fill();
    this.cx.stroke();

    this.cx.strokeStyle = "#ccc";
    this.cx.fillStyle = "#ccc"
    this.cx.fillText(i, x, y + r / 3);
    this.cx.stroke();

  }



  private async refresh(tmp: IDamage = null) {


    await this.updateSize();
    this.clear();

    //this.setBg();

    let bgRatio = this.bg.width / this.bg.height;

    console.log(bgRatio)

    this.cx.drawImage(this.bg, 0, 0, this.canvasEl.width, this.canvasEl.width / bgRatio);
    //console.log('redraw ' + this.width + 'x' + this.width / bgRatio)

    console.log(this.width + 'x' + this.width / bgRatio)

    if (tmp) this.drawOnCanvas(tmp, 'X');


    if (!this.value.damages) {
      console.log('null damages')
      return;
    }

    if (this.value.damages.length == 0) {
      console.log('empty damages')
      return;
    }

    for (let i = 0; i < this.value.damages.length; i++) {
      this.drawOnCanvas(this.value.damages[i], i + 1);
    }

    console.clear()
    console.log(this.canvasEl.toDataURL());

  }


  private clear() {
    if (!this.cx) return;
    this.cx.clearRect(0, 0, this.canvasEl.width, this.canvasEl.height);
  }

  mouseleave(e) {

    this.refresh();
  }

  mouseup(e) {

    this.isMouseDown = false;

    this.updateSize();
    let t = '';

    //this.setBg();

    let bgRatio = this.bg.width / this.bg.height;
    let x = e.layerX / this.canvasEl.width * 100;
    let y = e.layerY / (this.canvasEl.width / bgRatio) * 100;
    let r = 0.00625 * this.width * 3;

    let tmp: IDamage = {
      x: x,
      y: y,
      severity: this.currentSeverity,
      type: t
    }


    let d = this.getAlreadyPresentDamage(tmp);
    if (d) {
      console.log('danno già presente in questa coordinata', d);
      this.refresh();

      console.log(d)

      let tmp = { source: [d.img1, d.img2], i: 0, double: true, descr: [d.part.description] }

      for (let i = 0; d.extra && i < d.extra.length; i++) {
        tmp.source.push(d.extra[i].img1)
        tmp.source.push(d.extra[i].img2)

        tmp.descr.push(d.extra[i].descr)
        tmp.descr.push(d.extra[i].descr)
      }

      this.onSelected.emit(tmp)

      return;
    }


    //this.askForDamageImages(tmp)

    if (!this.disabled) this.onNew.emit(tmp);

  }

  getAlreadyPresentDamage(d: IDamage) {

    const isBetween = function (n1: number, n2: number, r = 2) {
      return n1 <= (n2 + r) && n1 >= (n2 - r)
    }

    for (let i = 0; i < this.value.damages.length; i++) {

      let d1 = this.value.damages[i];

      if (isBetween(d.x, d1.x) && isBetween(d.y, d1.y)) {
        return d1;
      }

    }

    return null;

  }


  /*
  askForDamageImages(tmp) {

    //this.value.damages.push(tmp)
    //this.refresh();

  }*/


  mousedown(e) {
    this.isMouseDown = true;
  }

  mousemove(e) {


    let bgRatio = this.bg.width / this.bg.height;
    let x = e.layerX / this.canvasEl.width * 100;
    let y = e.layerY / (this.canvasEl.width / bgRatio) * 100;

    let tmp = null/* {
      x: x,
      y: y,
      severity: this.currentSeverity,
      type: ''
    };*/

    //this.refresh(tmp); /// commentato perchè ingrandiva l'immagine


  }
  /*
    setSeverity(s: string) {
      this.currentSeverity = s;
    }
  */
  undo() {
    this.value.damages.splice(-1, 1);
    this.refresh();

    this.onDelete.emit();
  }

}
