<div class="main-content"
  style="height: calc(100vh - 20px);; padding-top:0; margin-bottom:0; padding-right: 20%;padding-left: 20%;">
  <div class="card" style="padding:1em">
    <div class="card-header card-header-info" style="margin-top: 3%;">
      <h5 class="card-title">Modifica Campi ADZ
        <a style="float:right" (click)="close()"><i class="material-icons">close</i></a>
      </h5>
    </div>
    <div class="card-body">

      <form [formGroup]="form">

        <div class="row">
          <div class="col-4" *ngIf="data.item.adz_data">

            <div class="row">
              <div class="col">
                <mat-form-field style="float:left">
                  <mat-label>Valore Commerciale</mat-label>
                  <input matInput type="number" [(ngModel)]="valore_commerciale" formControlName="valore_commerciale">
                </mat-form-field>
                <button type="button" mat-button class="mat-button" style="float:left" color="success"
                  (click)="save('valore_commerciale',valore_commerciale)">
                  <i class="material-icons">check</i>
                </button>
              </div>
            </div>


            <div class="row">
              <div class="col">
                <mat-form-field style="float:left">
                  <mat-label>Valore Relitto</mat-label>
                  <input matInput type="number" [(ngModel)]="valore_relitto" formControlName="valore_relitto">
                </mat-form-field>
                <button type="button" mat-button class="mat-button" style="float:left" color="success"
                  (click)="save('valore_relitto',valore_relitto)">
                  <i class="material-icons">check</i>
                </button>
              </div>
            </div>


            <div class="row">
              <div class="col">
                <mat-form-field style="float:left">
                  <mat-label>Valore per Differenza</mat-label>
                  <input matInput type="number" [(ngModel)]="valore_per_differenza"
                    formControlName="valore_per_differenza">
                </mat-form-field>
                <button type="button" mat-button class="mat-button" style="float:left" color="success"
                  (click)="save('valore_per_differenza',valore_per_differenza)">
                  <i class="material-icons">check</i>
                </button>
              </div>
            </div>


            <div class="row">
              <div class="col">
                <mat-form-field style="float:left">
                  <mat-label>Valore a Nuovo</mat-label>
                  <input matInput type="number" [(ngModel)]="valore_a_nuovo" formControlName="valore_a_nuovo">
                </mat-form-field>
                <button type="button" mat-button class="mat-button" style="float:left" color="success"
                  (click)="save('valore_a_nuovo',valore_a_nuovo)">
                  <i class="material-icons">check</i>
                </button>
              </div>
            </div>


            <div class="row">
              <div class="col">
                <mat-form-field style="float:left">
                  <mat-label>Osservazioni</mat-label>
                  <textarea matInput type="number" [(ngModel)]="osservazioni" formControlName="osservazioni"></textarea>
                </mat-form-field>
                <button type="button" mat-button class="mat-button" style="float:left" color="success"
                  (click)="save('osservazioni',osservazioni)">
                  <i class="material-icons">check</i>
                </button>
              </div>
            </div>

          </div>

          <div class="col">
            <div class="row" style="margin-left:15px;margin-right:15px; font-weight: bold;"
              *ngIf="data?.item?.valutazione_etax">
              <div class="col"
                style="background-color:#fbdd00; color:black; padding:10px; border-radius:15px;box-shadow:         2px 2px 9px 0px rgba(137, 137, 137, 0.51);">
                ETAX Giallo:
                <span style="float:right">
                  {{data?.item?.valutazione_etax?.valutazione?.quotazione_eurotax_giallo|
                  number:'.2-2'}}€
                </span>

                <br>
                ETAX Giallo (Km):
                <span style="float:right">
                  {{data?.item?.valutazione_etax?.valutazione?.quotazione_eurotax_giallo_km| number:'.2-2'}}€
                </span> <br>
              </div>
              <div class="col"
                style="background-color:#00059e; color:white; padding:10px; border-radius:15px; margin-left:5px;box-shadow:         2px 2px 9px 0px rgba(137, 137, 137, 0.51);">
                ETAX Blu:
                <span style="float:right">{{data?.item?.valutazione_etax?.valutazione?.quotazione_eurotax_blu|
                  number:'.2-2'}}€ </span><br>
                ETAX Blu (Km):
                <span style="float:right"> {{data?.item?.valutazione_etax?.valutazione?.quotazione_eurotax_blu_km|
                  number:'.2-2'}}€</span>
                <br>
              </div>
              <div class="col-12">
                * Periodo di riferimento: {{data?.item?.valutazione_etax?.valutazione?.ediz_dati}}
              </div>
            </div>

            <div class="row">
              <div class="col">
                <ng2-smart-table class="grid" [settings]="settings" [source]="source">
                </ng2-smart-table>
              </div>
            </div>

          </div>
        </div>



      </form>
    </div>
  </div>
