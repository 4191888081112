import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { MysqlService } from './mysql.service';

export const HIDDEN = 0;
export const EDITABLE = 1;
export const READONLY = 2;

export interface IPermission {
  permitted: boolean;
  tabs?: number[];
  permitStatusChange?: boolean;
  assignments?: IAssignment[];
  chats?: boolean[];
}

export interface IAssignment {
  permitView: boolean;
  permitChange: boolean;
}

@Injectable()
export class AuthorizationService {

  private authorizations: IPermission[] = [];

  constructor(private userService: UserService, private db: MysqlService) {

    //userService.getCurrentUser().role

    this.authorizations['INSURANCE'] = [];
    this.authorizations['INSURANCE']['CLIENTE'] = [];
    this.authorizations['INSURANCE']['MANDANTE'] = [];
    this.authorizations['INSURANCE']['SALACONTROLLO'] = [];
    this.authorizations['INSURANCE']['ISPETTORE'] = [];
    this.authorizations['INSURANCE']['GESTORE'] = [];
    this.authorizations['INSURANCE']['ADMIN'] = [];


    this.authorizations['INSURANCE']['CLIENTE'][''] = {
      permitted: false,
      tabs: [HIDDEN, HIDDEN, HIDDEN, HIDDEN, HIDDEN, HIDDEN, HIDDEN, HIDDEN, HIDDEN, HIDDEN, HIDDEN],
      permitStatusChange: false,
      assignments: [{ permitView: false, permitChange: false }, { permitView: false, permitChange: false }],
      chats: [false, false, false, false]

    };
    this.authorizations['INSURANCE']['MANDANTE'][''] = {
      permitted: false,
      tabs: [HIDDEN, HIDDEN, HIDDEN, HIDDEN, HIDDEN, HIDDEN, HIDDEN, HIDDEN, HIDDEN, HIDDEN, HIDDEN],
      permitStatusChange: false,
      assignments: [{ permitView: false, permitChange: false }, { permitView: false, permitChange: false }],
      chats: [false, false, false, false]
    };
    this.authorizations['INSURANCE']['SALACONTROLLO'][''] = {
      permitted: false,
      tabs: [HIDDEN, HIDDEN, HIDDEN, HIDDEN, HIDDEN, HIDDEN, HIDDEN, HIDDEN, HIDDEN, HIDDEN, HIDDEN],
      permitStatusChange: false,
      assignments: [{ permitView: false, permitChange: false }, { permitView: false, permitChange: false }],
      chats: [false, false, false, false]
    };
    this.authorizations['INSURANCE']['ISPETTORE'][''] = {
      permitted: false,
      tabs: [HIDDEN, HIDDEN, HIDDEN, HIDDEN, HIDDEN, HIDDEN, HIDDEN, HIDDEN, HIDDEN, HIDDEN, HIDDEN],
      permitStatusChange: false,
      assignments: [{ permitView: false, permitChange: false }, { permitView: false, permitChange: false }],
      chats: [false, false, false, false]
    };
    this.authorizations['INSURANCE']['ADMIN'][''] = {
      permitted: false,
      tabs: [HIDDEN, HIDDEN, HIDDEN, HIDDEN, HIDDEN, HIDDEN, HIDDEN, HIDDEN, HIDDEN, HIDDEN, HIDDEN],
      permitStatusChange: false,
      assignments: [{ permitView: false, permitChange: false }, { permitView: false, permitChange: false }],
      chats: [false, false, false, false]
    };


    ////////////////
    this.authorizations['INSURANCE']['CLIENTE']['NEW'] = {
      permitted: true,
      tabs: [true, true, true, true, HIDDEN, HIDDEN, HIDDEN, HIDDEN, HIDDEN, HIDDEN, HIDDEN],
      permitStatusChange: false,
      assignments: [{ permitView: false, permitChange: false }, { permitView: false, permitChange: false }],
      chats: [false, false, false, false]
    };
    this.authorizations['INSURANCE']['CLIENTE']['EDIT'] = {
      permitted: false,
      tabs: [READONLY, READONLY, READONLY, READONLY, false, false, true, false, false, READONLY, HIDDEN],
      permitStatusChange: false,
      assignments: [{ permitView: false, permitChange: false }, { permitView: false, permitChange: false }],
      chats: [false, false, false, true] // ispettore, sala, cliente, admin
    };
    this.authorizations['INSURANCE']['CLIENTE']['VIEW'] = { permitted: true };
    this.authorizations['INSURANCE']['CLIENTE']['DELETE'] = { permitted: false };

    ////////////////
    this.authorizations['INSURANCE']['MANDANTE']['NEW'] = {
      permitted: true,
      tabs: [true, true, true, true, HIDDEN, HIDDEN, HIDDEN, HIDDEN, HIDDEN, HIDDEN, HIDDEN],
      permitStatusChange: false,
      assignments: [{ permitView: false, permitChange: false }, { permitView: false, permitChange: false }],
      chats: [false, false, false, false]
    };
    this.authorizations['INSURANCE']['MANDANTE']['EDIT'] = {
      permitted: false,
      tabs: [true, true, true, true, false, false, true, false, false, HIDDEN],
      permitStatusChange: false,
      assignments: [{ permitView: false, permitChange: false }, { permitView: false, permitChange: false }],
      chats: [false, false, false, false] // ispettore, sala, cliente, admin
    };
    this.authorizations['INSURANCE']['MANDANTE']['VIEW'] = { permitted: true };
    this.authorizations['INSURANCE']['MANDANTE']['DELETE'] = { permitted: false };


    ////////////////
    this.authorizations['INSURANCE']['SALACONTROLLO']['NEW'] = {
      permitted: false,
      tabs: [true, true, true, true, HIDDEN, HIDDEN, HIDDEN, HIDDEN, HIDDEN, HIDDEN, HIDDEN],
      permitStatusChange: false,
      assignments: [{ permitView: true, permitChange: false }, { permitView: true, permitChange: true }],
      chats: [false, false, false, false]
    };
    this.authorizations['INSURANCE']['SALACONTROLLO']['EDIT'] = {
      permitted: true,
      tabs: [READONLY, READONLY, READONLY, READONLY, true, true, true, true, false, true],
      permitStatusChange: false,
      permitLiveXpert: true,
      assignments: [{ permitView: true, permitChange: false }, { permitView: true, permitChange: true }],
      chats: [true, false, false, true] // ispettore, sala, cliente, admin
    };
    this.authorizations['INSURANCE']['SALACONTROLLO']['VIEW'] = { permitted: true };
    this.authorizations['INSURANCE']['SALACONTROLLO']['DELETE'] = { permitted: false };

    ////////////////
    this.authorizations['INSURANCE']['ISPETTORE']['NEW'] = {
      /*
      permitted: false,
      tabs: [true, true, true, true, HIDDEN, HIDDEN, HIDDEN, HIDDEN, HIDDEN, HIDDEN],
      permitStatusChange: false,
      assignments: [{ permitView: false, permitChange: false }, { permitView: false, permitChange: false }],
      chats: [false, false, false, false]
      */
      permitted: true,
      tabs: [true, true, true, true, true, HIDDEN, HIDDEN, HIDDEN, HIDDEN, HIDDEN, HIDDEN],
      permitStatusChange: false,
      assignments: [{ permitView: false, permitChange: false }, { permitView: false, permitChange: false }],
      chats: [false, false, false, false]
    };
    this.authorizations['INSURANCE']['ISPETTORE']['EDIT'] = {
      permitted: true,
      tabs: [READONLY, READONLY, READONLY, READONLY, true, true, true, true, false, true, true],
      permitStatusChange: false,
      permitLiveXpert: true,
      assignments: [{ permitView: false, permitChange: false }, { permitView: false, permitChange: false }],
      chats: [false, true, false, false] // ispettore, sala, cliente, admin
    };
    this.authorizations['INSURANCE']['ISPETTORE']['VIEW'] = { permitted: true };
    this.authorizations['INSURANCE']['ISPETTORE']['DELETE'] = { permitted: false };

    ////////////////
    this.authorizations['INSURANCE']['ADMIN']['NEW'] = {
      permitted: true,
      tabs: [true, true, true, true, HIDDEN, HIDDEN, HIDDEN, HIDDEN, HIDDEN, HIDDEN, HIDDEN],
      permitStatusChange: true,
      assignments: [{ permitView: true, permitChange: true }, { permitView: true, permitChange: true }],
      chats: [false, false, false, false]
    };
    this.authorizations['INSURANCE']['ADMIN']['EDIT'] = {
      permitted: true,
      tabs: [true, true, true, true, true, true, true, true, true, true, true],
      permitStatusChange: true,
      permitLiveXpert: true,
      assignments: [{ permitView: true, permitChange: true }, { permitView: true, permitChange: true }],
      chats: [true, true, true, false] // ispettore, sala, cliente, admin
    };
    this.authorizations['INSURANCE']['ADMIN']['VIEW'] = { permitted: true };
    this.authorizations['INSURANCE']['ADMIN']['DELETE'] = { permitted: true };

    ////////////////
    this.authorizations['INSURANCE']['GESTORE']['NEW'] = {
      permitted: true,
      tabs: [true, true, true, true, HIDDEN, HIDDEN, HIDDEN, HIDDEN, HIDDEN, HIDDEN, HIDDEN],
      permitStatusChange: false,
      assignments: [{ permitView: true, permitChange: true }, { permitView: true, permitChange: true }],
      chats: [false, false, false, false]
    };
    this.authorizations['INSURANCE']['GESTORE']['EDIT'] = {
      permitted: true,
      tabs: [READONLY, READONLY, READONLY, READONLY, READONLY, READONLY, READONLY, READONLY, HIDDEN, READONLY, true],
      permitStatusChange: true, //modificato richiesta vinciarelli
      assignments: [{ permitView: true, permitChange: true }, { permitView: true, permitChange: true }],
      chats: [true, true, true, false] // ispettore, sala, cliente, admin
    };
    this.authorizations['INSURANCE']['GESTORE']['VIEW'] = { permitted: true };
    this.authorizations['INSURANCE']['GESTORE']['DELETE'] = { permitted: false };
  }

  isAuthorized(modulo: string, funct: string): IPermission {

    let role = this.userService.getCurrentUser().role;

    let p = this.authorizations
    [modulo.toUpperCase()]
    [role.toUpperCase()]
    [funct.toUpperCase()]
      ;

    console.log(p)

    return p;

  }

  isAdmin(): boolean {
    return this.userService.isAdmin();
  }

  isBackoffice(): boolean {
    if (this.userService.isAdmin()) return true;
    return this.userService.isBackoffice();
  }

  isUserCompetence(sts: string): boolean {

    if (this.userService.isAdmin()) {
      return true;
    }

    let role: string = this.userService.getCurrentUser().role;


    try {
      let s: string = this.db.getStato(sts).competence;
      return s.indexOf(role) >= 0;
    } catch (error) {
    }

    return false

  }

}
