import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-commesse-dialog',
  templateUrl: './commesse-dialog.component.html',
  styleUrls: ['./commesse-dialog.component.scss']
})
export class CommesseDialogDialogComponent implements OnInit {
  form : FormGroup
  tipoVeicolo
  documentale
  ricavo
  costo
  type='new'
  constructor(  public dialogRef: MatDialogRef<CommesseDialogDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder : FormBuilder) {
      if(this.data){
        this.tipoVeicolo = data.vehType
        this.ricavo = data.ricavo
        this.costo = data.costo
        this.documentale = data.documentale
        this.type='edit'
      }
      this.form = this.formBuilder.group({

        tipoVeicolo: ['', Validators.required],
        documentale: ['', [Validators.required]],
        ricavo: ['', Validators.required],
        costo: ['', Validators.required],
      
      });
  
     }

  ngOnInit(): void {
  }
   close(){
     this.dialogRef.close()
   }

   save(){
     let type = this.type
     let item = {  vehType : this.tipoVeicolo,
      documentale : this.documentale,
      ricavo : this.ricavo,
      costo: this.costo
    }
  this.dialogRef.close({item : item, type : type})

   }
}
