import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'presentImage'
})
export class PresentImagePipe implements PipeTransform {

  transform(value: any[], args?: any): any {

    let valuex = value.filter(x => { return !x.url.includes('null') })

    return valuex;
  }

}
