import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

import * as moment from 'moment-timezone';
@Component({
  selector: 'app-datetime',
  templateUrl: './datetime.component.html',
  styleUrls: ['./datetime.component.scss']
})
export class DatetimeComponent implements OnInit {

  form: FormGroup;

  _d
  _t

  @Input() model;
  @Output() onChange: EventEmitter<any> = new EventEmitter();
  constructor(
    private formBuilder: FormBuilder,
  ) {

    this.form = this.formBuilder.group({
      d: ['',],
      t: ['',],
    })

  }

  ngOnInit() {



    }

  change() {

    if(!this._d || !this._t) return

    
    console.log(this._d, this._t)

    this.model = moment(this._d + 'T' + this._t)

    this.onChange.emit(this.model)
  }

}
