import { AuthorizationService } from './services/authorization.service';
import { OsmService } from './services/osm.service';
import { MysqlService } from './services/mysql.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, LOCALE_ID } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';
import { AppComponent } from './app.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { HttpClientModule } from '@angular/common/http';
import { LoginRouteGuard, AdminRouteGuard, NotLoginRouteGuard } from './route-guard';
import { UserService } from './services/user.service';
import { DecimalPipe, registerLocaleData, DatePipe } from '@angular/common'


import localeIt from '@angular/common/locales/it';
import { FilterByFileTypePipe } from './pipes/filter-by-file-type.pipe';
import { ReportComponent } from './report/report.component';
import { ServerityPipe } from './pipes/serverity.pipe';
import { OpenWeatherService } from './services/open-weather.service';
import { PipesModule } from './pipes/pipes.module';
import { XlsButtonComponent } from './components/xls-button/xls-button.component';
import { ProgramComponent } from './menu/demo/program/program.component';
import { AgGridModule } from 'ag-grid-angular'
import { CommesseDialogDialogComponent } from './menu/options/commesse/dialog/commesse-dialog/commesse-dialog.component';
import { CommesseDialogDialogManualiComponent } from './menu/options/commesse/dialog/commesse-dialog-manuali/commesse-dialog-manuali.component';
import { DialogCheckComponent } from './menu/automotive/perizie/dialog/dialog-check/dialog-check.component';
import { GridEditComponent } from './components/grid-edit/grid-edit.component';

registerLocaleData(localeIt, 'it');



@NgModule({
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    ComponentsModule,
    RouterModule,
    AppRoutingModule,
    HttpClientModule,
    AgGridModule.withComponents([GridEditComponent]),
    PipesModule,

  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    ReportComponent,
    ServerityPipe,
    ProgramComponent,
    CommesseDialogDialogManualiComponent,
    CommesseDialogDialogComponent,
    DialogCheckComponent,
    GridEditComponent,




  ],
  providers: [
    MysqlService,
    DatePipe,
    LoginRouteGuard,
    AdminRouteGuard,
    NotLoginRouteGuard,
    UserService,
    DecimalPipe,
    FilterByFileTypePipe,
    OsmService,
    AuthorizationService,
    { provide: LOCALE_ID, useValue: 'it' },
    ServerityPipe,
    OpenWeatherService,

    PipesModule,


  ],

  exports: [

    ServerityPipe,
    XlsButtonComponent

  ],

  bootstrap: [AppComponent],


})



export class AppModule {

}


