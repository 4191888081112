<div
    [ngClass]="{ 'card':true, 'card_dash':true, 'card_dash_blue':_color=='blue', 'card_dash_yellow':_color=='yellow', 'card_dash_green':_color=='green', 'card_dash_red':_color=='red' , 'card_dash_gray':_color=='gray' , 'card_dash_violet':_color=='violet'  }">


    <div class="numberCircle" *ngIf="badge >0" (click)="openBadge()" style="cursor:pointer">{{badge}}</div>

    <div class="card_dash_row card_dash_row_top">
        <div class="card_dash_title" style="cursor:pointer" (click)="openMain()">
            {{_title}}
        </div>



    </div>

    <div class="card_dash_row card_dash_row_middle" (click)="openMain()">

        <div *ngIf="!(_icon.substring(0, 1) == '.')" class="card_dash_icon">
            <i class="material-icons">{{_icon}}</i>
        </div>
        <div *ngIf="_icon == './assets/img/icons/icon0.png'  " class="card_dash_icon">
            <img [src]="'./assets/img/icons/icon0.png'">
        </div>
        <div *ngIf="_icon == './assets/img/icons/icon10.png'  " class="card_dash_icon">
            <img [src]="'./assets/img/icons/icon10.png'">
        </div>

        <div
            [ngClass]="{'card_dash_main_value': true, 'animated': _main_value_curr==_main_value, 'tada': _main_value_curr==_main_value }">
            <span>{{_main_value_curr | number: _main_value_mask}}</span>
        </div>

    </div>

    <div class="card_dash_row card_dash_row_footer" style="font-weight: bold; cursor:pointer" *ngIf="_category || _category_value_curr"  (click)="openCategory()">
        
        <div class="row">

            <div class="col card_dash_category" >
                {{_category}}
            </div>
    
            <div *ngIf="_category_value!=undefined" style="font-weight: bold;" 
                [ngClass]="{'col': true, 'card_dash_category_value': true, 'animated': _category_value_curr==_category_value, 'tada': _category_value_curr==_category_value }">
                {{_category_value_curr}}
            </div>

        </div>
        
        

    </div>

</div>