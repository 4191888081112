<div class="row">
    <div class="col-4">
        <app-tire (onChange)="changed($event)" [(tire)]="value?.tires[0]" title="Anteriore SX"
            style="max-width: 100%!important;" (onOpenViewer)="openViewer($event)"></app-tire>
    </div>
    <div class="col-4">
        <img src="./assets/img/tires-car.png" style="width:90%; display:block; margin:auto;">
    </div>
    <div class="col-4">
        <app-tire (onChange)="changed($event)" [(tire)]="value?.tires[1]" title="Anteriore DX"
            style="max-width: 100%!important;" (onOpenViewer)="openViewer($event)"></app-tire>
    </div>
</div>


<div class="row">
    <div class="col-4">
        <app-tire (onChange)="changed($event)" [(tire)]="value?.tires[2]" title="Posteriore SX"
            style="max-width: 100%!important;" (onOpenViewer)="openViewer($event)"></app-tire>
    </div>
    <div class="col-4">
        &nbsp;
    </div>
    <div class="col-4">
        <app-tire (onChange)="changed($event)" [(tire)]="value?.tires[3]" title="Posteriore DX"
            style="max-width: 100%!important;" (onOpenViewer)="openViewer($event)"></app-tire>
    </div>
</div>

<div class="row">
    <div class="col-4">

    </div>
    <div class="col-4">
        <app-tire (onChange)="changed($event)"  [(tire)]="value?.tires[4]" [kit]="value?.spareTireNotPreset"
            [title]="value?.spareTireNotPreset ? 'KIT Gonfiaggio' : 'Ruota di scorta'" (onOpenViewer)="openViewer($event)">
        </app-tire>
    </div>
    <div class="col-4">

    </div>
</div>
