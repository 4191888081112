import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IForm } from 'app/models/form.interface';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MysqlService } from 'app/services/mysql.service';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {

  form;

  @Input() disabled: boolean = false;
  @Input() result: any;


  @Input('id') set setId(id) {

    this.db.forms_get(id).then(res => {
      console.log(res)
      this.setFormx(res.data)
    })

  }

  @Input('code') set setcode(code) {

    this.db.forms_get_by_code(code).then(res => {
      console.log(res)
      this.setFormx(res.data)
    })

  }

  @Input('form') set setForm(form) {

    console.log('init form', form)

    this.setFormx(form)


  };

  @Output() isValid = new EventEmitter<boolean>();



  setFormx(form) {

    setTimeout(() => {


      console.log('setFormx', form, this.result)

      var xformBuildier = {};

      form.rows.forEach(row => {
        row.cols.forEach(col => {
          if (!col) return;
          if (col.id) {
            xformBuildier[col.id] = new FormControl({ disabled: this.disabled }, col.mandatory ? Validators.required : undefined)
            if (!this.result || this.result == '"null"' || this.result == 'null') this.result = {};
            //console.log('setFormx',form, this.result)

            if (!this.result[col.id])
              if (col.type == 'input-address')
                this.result[col.id] = {};
              else
                this.result[col.id] = undefined;
          }
        })

      });

      console.log('setFormx', form, this.result)

      console.log('xformBuildier', xformBuildier)


      this.xform = this.formBuilder.group(xformBuildier)
      this.form = form;

    }, 100);


  }

  xform: FormGroup;

  constructor(private formBuilder: FormBuilder, private db: MysqlService) {


    /*
        this.xform = formBuilder.group({
          email: ['', Validators.required],
          password: ['', Validators.required],
        });
    */


    setInterval(() => {

      try {
        this.isValid.emit(this.xform.valid)
      } catch (error) {

      }

    }, 100)

  }

  ngOnInit() {

  }

  dropFile(event, id) {

    if (!event.content.match('image')) {
      alert("Immagine non valida")
      return;
    }

    console.log(event)

    this.result[id] = event.content;

  }




  onLocationSelected(id, event) {


    this.result[id].cap = event.CAP
    this.result[id].citta = event.Citta
    this.result[id].indirizzo = event.Indirizzo
    this.result[id].provincia = event.Prov
    this.result[id].location_lat = event.location_lat
    this.result[id].location_lon = event.location_lon


  }




  showTooltip(string) {
    alert(string)
  }


  getMediumColor(x: string) {

    if (!x) return 255

    try {

      //console.log('color ')
      let x1 = x.substr(1, 2)
      let n1 = parseInt('0x' + x1)
      //console.log('color ' + x1 + ' ' + n1)
      let x2 = x.substr(3, 2)
      let n2 = parseInt('0x' + x2)
      //console.log('color ' + x2 + ' ' + n2)
      let x3 = x.substr(5, 2)
      let n3 = parseInt('0x' + x3)
      //console.log('color ' + x3 + ' ' + n3)
      let n = (n1 + n2 + n3) / 3
      return n

    } catch (error) {
      console.error(error)
      return 255
    }

  }


}
