import {  LoginRouteGuard,  } from './route-guard';
import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { ReportComponent } from './report/report.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'automotive/dashboard',
    pathMatch: 'full',
    canActivate: [LoginRouteGuard]
  },


  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./layouts/admin-layout/admin-layout.module').then(m => m.AdminLayoutModule)
      }]
  },

  

  {
    path: 'report/:type/:id',
    component: ReportComponent,
    children: [
      {
        path: 'report/:type/:id',
        loadChildren: () => import('./layouts/flat-layout/flat-layout.module').then(m => m.FlatLayoutModule)
      }]
  },

 
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, { useHash: true })
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
