import { NgModule } from "@angular/core";
import { CountdownPipe } from "./countdown.pipe";
import { CommonModule } from "@angular/common";
import { DurationPipe } from "./duration.pipe";
import { PresentImagePipe } from "./present-image.pipe";
import { MultilinePipe } from "./multiline.pipe";
import { NameToInitialPipe } from "./name-to-initial.pipe";

@NgModule({

  declarations: [
    CountdownPipe,
    DurationPipe,
    PresentImagePipe,
    MultilinePipe,
    NameToInitialPipe,

  ],
  imports: [
    CommonModule,
  ],
  exports: [
    CountdownPipe,
    DurationPipe,
    PresentImagePipe,
    MultilinePipe,
    NameToInitialPipe,

  ],

  providers: [CountdownPipe,
    DurationPipe,]
})
export class PipesModule { }
