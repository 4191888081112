import { UserService } from './../../services/user.service';
import { MysqlService } from './../../services/mysql.service';
import { Component, OnInit, ElementRef, Input, ViewChild } from '@angular/core';
import { ROUTES } from '../sidebar/sidebar.component';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { BASE_TREE_DATA } from '../sidebar-tree/sidebar.component';

interface RouteInfo {
    path?: string;
    name: string;
    icon: string;
    class?: string;
    separator?: boolean;
    child?: boolean;
    disabled?: boolean;
    adminOnly?: boolean;
    permissions?: string[];
    userPermissionFlag?: string;
    newPage?: boolean;

    children?: RouteInfo[];

}

interface ExampleFlatNode {
    expandable: boolean;
    name: string;
    level: number;
}

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {




    nav

    menuItems: any[];
    categories: any[];


    user;

    logo;



    ////////////// TREE /////////////////////
    private _transformer = (node: RouteInfo, level: number) => {
        return {
            expandable: !!node.children && node.children.length > 0,
            name: node.name,
            icon: node.icon,


            path: node.path,
            class: node.class,
            separator: node.separator,
            child: node.child,
            permissions: node.permissions,
            userPermissionFlag: node.userPermissionFlag,
            newPage: node.newPage,

            level: level,
        };
    }

    //treeControl = new FlatTreeControl<ExampleFlatNode>( node => node.level, node => node.expandable);

    //treeFlattener = new MatTreeFlattener( this._transformer, node => node.level, node => node.expandable, node => node.children);

    //dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    hasChild = (_: number, node: ExampleFlatNode) => node.expandable;
    //////////////////////////////////////////

    TREE_DATA: RouteInfo[] = BASE_TREE_DATA

    navItems: NavItem[]
    uItems: NavItem[] = [

        { name: 'Cambia password', icon: 'lock', fn: () => this.changePwd() },
        { name: "Log-out", icon: 'logout', fn: () => this.logout() },


    ]

    private listTitles: any[];
    location: Location;
    mobile_menu_visible: any = 0;
    private toggleButton: any;
    private sidebarVisible: boolean;

    notifies = 0;
    notifiesMenu = [{ link: null, title: 'Non hai nuovi messaggi' }];


    constructor(
        location: Location, private element: ElementRef, private router: Router, private db: MysqlService, private userService: UserService
    ) {


        this.location = location;
        this.sidebarVisible = false;

        //this.router.onSameUrlNavigation = 'reload'


        this.logo = db.getLogo();
        //this.dataSource.data = [];

    }

    menus = []

    ngOnInit() {


        this.listTitles = ROUTES.filter(listTitle => listTitle);
        const navbar: HTMLElement = this.element.nativeElement;
        this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
        this.router.events.subscribe((event) => {

            var $layer: any = document.getElementsByClassName('close-layer')[0];
            if ($layer) {
                $layer.remove();
                this.mobile_menu_visible = 0;
            }
        });


        this.userService.onUserState()
            .subscribe((user: any) => {
                this.user = user;


                this.refreshNotifies();

                this.db.get_current_user_profile(true).then(res => {

                    this.user = res[0]

                    let data = JSON.parse(JSON.stringify(this.TREE_DATA));
                    data = this.removeNotAuthorized(data)
                    console.log(data)


                    this.navItems = data


                });

            });



    }

    xxxx(parent, childs: any[]) {

        if (!childs) return

        childs.forEach(child => {
            this.menus.push({ id: parent.name, name: child.name, icon: child.icon, triggerable: child.children && child.children.length > 0 })
            this.xxxx(child, child.children)
        })


    }

    getMenu(id) {
        return this.menus.filter(e => e.id == id)
    }


    listMenus() {

        let unique = [...new Set(this.menus.map(e => e.id))];

        console.log(unique)

        return unique

    }


    removeNotAuthorized(nodes: RouteInfo[]): RouteInfo[] {

        let newNodes = []

        if (!nodes) return undefined;

        for (let i = 0; i < nodes.length; i++) {

            let node = nodes[i];
            let b = this.checkUserPermissionFlag(node)

            if (b) {
                node.children = this.removeNotAuthorized(node.children)
                if ((node.children && node.children.length > 0) || node.path) {
                    newNodes.push(node)
                }

            }

        }

        return newNodes;

    }


    checkUserPermissionFlag(menuItem: RouteInfo): boolean {

        if (!menuItem.userPermissionFlag) return true;

        if (!this.user) return false;


        let x;
        try {
            x = this.user.permissions[menuItem.userPermissionFlag];
        } catch (error) {
            console.error(error)
            return false;
        }
        return +x > 0

    }

    openUrl(url) {
        this.router.navigate([url], { queryParams: { ts: (new Date()).getTime() } });
    }

    logout() {
        this.userService.logout();
        this.router.navigate(['/login']);
    }

    changePwd() {

        this.db.user_change_pwd_request().then(res => {
            console.log(res);
            alert("Richiesta di modifica password inviata per email")
        }).catch(err => {
            console.error(err);
            alert(JSON.stringify(err))
        });


    }



    getTitle() {
        var titlee = this.location.prepareExternalUrl(this.location.path());
        if (titlee.charAt(0) === '#') {
            titlee = titlee.slice(2);
        }
        titlee = titlee.split('/').pop();

        for (var item = 0; item < this.listTitles.length; item++) {
            if (this.listTitles[item].path === titlee) {
                return this.listTitles[item].title;
            }
        }
        return 'Dashboard';
    }


    refreshNotifies() {

        this.db.getListNotifies().then(res => {

            //console.log('getListNotifies', res)

            let tmp = [];
            let n = 0;

            for (let i = 0; i < res.length; i++) {

                n += +res[i].n;

                switch (res[i].type) {

                    case 'chat':

                        switch (res[i].code.split('-')[0]) {
                            case 'perizie':
                                var type = 'perizia';
                                var id = res[i].code.split('-')[1];
                                var link = `/automotive/perizie/dettaglio/${id}/6`;
                                var t = `Hai ${res[i].n} messaggi/o da ${res[i].sender} per la ${type} ${id}`;
                                //var tipo = 'chat';

                                break;

                            case 'irepair':
                                var type = 'irepair';
                                var id = res[i].code.split('-')[1];
                                var link = `/automotive/irepair/dettaglio/${id}/6`;
                                var t = `Hai ${res[i].n} messaggi/o da ${res[i].sender} per ${type} ${id}`;
                                //var tipo = 'chat';

                                break;
                        }

                        break;

                    case 'eshop-sold':

                        var link = `/ecommerce/detail/${res[i].id}`;
                        var t = `Vendita conclusa: ${res[i].sender}`;
                        //var tipo = 'chat';

                        break;

                    case 'eshop-buoght':

                        var link = `/ecommerce/detail/${res[i].id}`;
                        var t = `Acquisto concluso: ${res[i].sender}`;
                        //var tipo = 'chat';

                        break;

                    case 'eshop-auction_losing':

                        var link = `/ecommerce/detail/${res[i].id}`;
                        var t = `Stai perdendo l'asta: ${res[i].sender}`;
                        //var tipo = 'chat';

                        break;
                    case 'new-perizia':
                        var type = 'perizia';
                        var id = res[i].code.split('-')[1];
                        var link = `/automotive/perizie/dettaglio/${id}/6`;
                        var t = `Hai ${res[i].n} messaggi/o da ${res[i].sender} per la ${type} ${id}`;
                }



                tmp.push({ title: t, link: link });
            }

            if (tmp.length == 0) {
                tmp = [{ title: 'Non hai nuovi messaggi' }];
            }

            this.notifies = n;

            this.notifiesMenu = tmp;

            setTimeout(() => { this.refreshNotifies() }, 5000);
        }).catch(err => {
            console.error(err);
            setTimeout(() => { this.refreshNotifies() }, 5000);
        })

    }
}

@Component({
    selector: 'app-navbar-item',
    template: `
    
    <mat-menu #childMenu="matMenu" [overlapTrigger]="false"  yPosition="below" xPosition="after">
    <span *ngFor="let child of items">
        <!-- Handle branch node menu items -->
        <span *ngIf="child.children && child.children.length > 0">
            <button mat-menu-item color="primary" [matMenuTriggerFor]="menu.childMenu">
                <mat-icon>{{child.icon}}</mat-icon>
                <span>{{child.name}}</span>
            </button>
            <app-navbar-item #menu [items]="child.children"></app-navbar-item>
        </span>


        <!-- Handle leaf node menu items -->
        <span *ngIf="(!child.children || child.children.length === 0) && !child.fn" >
            <button mat-menu-item [routerLink]="child.path"> 
                <mat-icon>{{child.icon}}</mat-icon>
                <span>{{child.name}}</span>
            </button>
        </span>


        <!-- Handle leaf node menu items -->
        <span *ngIf="(!child.children || child.children.length === 0) && child.fn">
            <button mat-menu-item  (click)="child.fn()" > 
                <mat-icon>{{child.icon}}</mat-icon>
                <span>{{child.name}}</span>
            </button>
        </span>


    </span>
    </mat-menu>


    `,
    styleUrls: []
})

export class NavbarComponentItem implements OnInit {

    @Input() items: NavItem[];
    @ViewChild('childMenu', { static: true }) public childMenu: any;

    constructor(public router: Router) {
    }

    ngOnInit() {
    }
}


export interface NavItem {
    name: string;
    icon: string;
    route?: string;
    children?: NavItem[];
    fn?: any
}
