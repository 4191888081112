<div class="wrapper">


    <div class="topmenu">
        <app-navbar></app-navbar>
    </div>
    <!--
    <div class="sidebar" *ngIf="user" data-color="danger">
        <app-sidebar-tree></app-sidebar-tree>
    </div>

-->

    <div class="cnt"
        [ngClass]="{'main-panel': user!=undefined && user!={}, 'main-panel-nologin': user==undefined || user=={} }">

        <router-outlet></router-outlet>

        <!--        
        <div *ngIf="isMaps('maps')">
            <app-footer></app-footer>
        </div>

        -->

    </div>
</div>