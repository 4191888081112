import { UserService } from './../../services/user.service';
import { Component, OnInit } from '@angular/core';
import { MysqlService } from 'app/services/mysql.service';
import { Router } from '@angular/router';

import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlattener, MatTreeFlatDataSource } from '@angular/material/tree';

declare const $: any;
declare interface RouteInfo {
    path?: string;
    name: string;
    icon: string;
    class?: string;
    separator?: boolean;
    child?: boolean;
    disabled?: boolean;
    adminOnly?: boolean;
    permissions?: string[];
    userPermissionFlag?: string;
    newPage?: boolean;

    children?: RouteInfo[];

}




/** Flat node with expandable and level information */
interface ExampleFlatNode {
    expandable: boolean;
    name: string;
    level: number;
}


@Component({
    selector: 'app-sidebar-tree',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarTreeComponent implements OnInit {
    menuItems: any[];
    categories: any[];


    user;

    logo;

    ////////////// TREE /////////////////////
    private _transformer = (node: RouteInfo, level: number) => {
        return {
            expandable: !!node.children && node.children.length > 0,
            name: node.name,
            icon: node.icon,


            path: node.path,
            class: node.class,
            separator: node.separator,
            child: node.child,
            permissions: node.permissions,
            userPermissionFlag: node.userPermissionFlag,
            newPage: node.newPage,

            level: level,
        };
    }

    treeControl = new FlatTreeControl<ExampleFlatNode>(
        node => node.level, node => node.expandable);

    treeFlattener = new MatTreeFlattener(
        this._transformer, node => node.level, node => node.expandable, node => node.children);

    dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    hasChild = (_: number, node: ExampleFlatNode) => node.expandable;
    //////////////////////////////////////////

    TREE_DATA: RouteInfo[] = BASE_TREE_DATA

    constructor(private userService: UserService, public db: MysqlService, private router: Router) {



        this.logo = db.getLogo();
        this.dataSource.data = [];

    }

    removeNotAuthorized(nodes: RouteInfo[]): RouteInfo[] {

        let newNodes = []

        if (!nodes) return undefined;

        for (let i = 0; i < nodes.length; i++) {

            let node = nodes[i];
            let b = this.checkUserPermissionFlag(node)

            if (b) {
                node.children = this.removeNotAuthorized(node.children)
                if ((node.children && node.children.length > 0) || node.path) {
                    newNodes.push(node)
                }

            }

        }

        return newNodes;

    }

    ngOnInit() {

        console.log(this.userService.isAdmin())


        this.userService.onUserState()
            .subscribe((user: any) => {

                console.log('onUserState')

                this.db.get_current_user_profile(true).then(res => {

                    this.user = res[0]


                    let data = JSON.parse(JSON.stringify(this.TREE_DATA));
                    data = this.removeNotAuthorized(data)
                    console.log(data)

                    //data[0].children.filter(child => child.path == '/automotive/livexpert')[0].name = this.db.getIcon()=='gg' || this.db.getIcon()=='gg-demo'  ? 'DirectXpert' : 'LiveXpert'
                    this.dataSource.data = data

                    console.log('auth obs')
                });

            });



    }

    open(menuItem) {

        if (!menuItem.path) {

            console.log('expanded: ' + this.treeControl.isExpanded(menuItem))

            if (!this.treeControl.isExpanded(menuItem))
                this.treeControl.expand(menuItem)
            else
                this.treeControl.collapse(menuItem)
            return;
        }

        console.log(menuItem)

        if (!menuItem.newPage)
            this.router.navigate([menuItem.path])
        else {

            if (menuItem.path.startsWith('http')) {
                window.open(menuItem.path, '_blank')
            } else {
                let url = this.router.createUrlTree([menuItem.path], {})
                window.open(url.toString().replace(menuItem.path, '#' + menuItem.path), '_blank')
            }

        }

    }


    checkUserPermissionFlag(menuItem: RouteInfo): boolean {

        if (!menuItem.userPermissionFlag) return true;

        if (!this.user) return false;


        let x;
        try {
            x = this.user.permissions[menuItem.userPermissionFlag];
        } catch (error) {
            console.error(error)
            return false;
        }
        return +x > 0

    }

}



export const BASE_TREE_DATA = [
    {
        name: 'AUTOMOTIVE', icon: 'commute',
        children: [

            { path: '/automotive/dashboard', name: 'Dashboard', icon: 'dashboard', class: '', separator: false, userPermissionFlag: 'automotive_dashboard' },
            { path: '/automotive/perizie', name: "Ispezioni di rientro", icon: 'security', class: '', separator: false, userPermissionFlag: 'automotive_insurance', },
     

        ]
    },


    {
        name: 'OPZIONI', icon: 'settings',
        children: [

            { path: '/options/users', name: "Utenti", icon: 'account_box', class: '', separator: false, userPermissionFlag: 'options_users' },
            { path: '/options/registry/', name: "Anagrafiche", icon: 'content_paste', class: '', separator: false, userPermissionFlag: 'options_registry' },
            { path: '/options/form-composer', name: "Form composer", icon: 'priority_high', class: '', separator: false, userPermissionFlag: 'options_formcomposer' },
            { path: '/options/commesse', name: "Commesse", icon: 'priority_high', class: '', separator: false, userPermissionFlag: 'options_commesse' },
           
        ]
    },

    { path: '/info', name: 'INFO', icon: 'info', class: '', separator: false, userPermissionFlag: 'options_info' },
];