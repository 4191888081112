import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
/// <reference path="../../../../../../node_modules/bingmaps/types/MicrosoftMaps/Microsoft.Maps.All.d.ts" />

@Component({
  selector: 'app-location-search',
  templateUrl: './location-search.component.html',
  styleUrls: ['./location-search.component.scss']
})
export class LocationSearchComponent implements OnInit {

  @Input() height = '24vh'

  @Input() location_lat
  @Input() location_lon

  @Output() onSelected = new EventEmitter<any>();

  @Input() showMap = true;

  
  @Input() disabled: boolean = false;

  locationSearch = '';

  form_location_search: FormGroup;

  loc: any = {}

  id: string

  constructor(
    private formBuilder: FormBuilder,
  ) {

    this.id = Math.random().toString(36).substring(7);

    console.log(this.id)

    console.log(`location: ${this.location_lat},${this.location_lon}`)


    this.form_location_search = this.formBuilder.group({
      locationSearch: ['',],
    })
  }

  ngOnInit() {
    setTimeout(() => {
      this.autosuggest()
    }, 100);
  }


  map;
  autosuggest() {

    if (!this.map) {

      let c = new Microsoft.Maps.Location(this.location_lat ? this.location_lat : 0, this.location_lon ? this.location_lon : 0)

      this.map = new Microsoft.Maps.Map(document.getElementById('map_' + this.id), {
        credentials: 'AvShsxmUDnD3rCAp_kwUxadhZRuRtKMXHGstTPBMEjAFYnY9ekRdDZKSkj96iYYm',
        center: c,
        zoom: 12
      });

      this.map.entities.clear();
      var pushpin = new Microsoft.Maps.Pushpin(c);
      console.log('pushpin', pushpin)
      this.map.entities.push(pushpin);

    }

    let x: Microsoft.Maps.IModuleOptions = {
      credentials: 'AvShsxmUDnD3rCAp_kwUxadhZRuRtKMXHGstTPBMEjAFYnY9ekRdDZKSkj96iYYm',
      callback: () => {
        var manager = new Microsoft.Maps.AutosuggestManager({
          placeSuggestions: false,
          addressSuggestions: true,
          map: this.map
        });
        manager.attachAutosuggest('#searchBox_' + this.id, '#searchBoxContainer_' + this.id, res => {
          console.log(res)


          this.loc.Prov = res.address.district ? res.address.district : res.address.locality
          this.loc.Citta = res.address.locality
          this.loc.CAP = res.address.postalCode
          this.loc.Indirizzo = res.address.addressLine

          this.loc.location_lat = res.location.latitude
          this.loc.location_lon = res.location.longitude

          console.log(this.loc)

          this.map.entities.clear();
          this.map.setView({ bounds: res.bestView });
          var pushpin = new Microsoft.Maps.Pushpin(res.location);
          console.log('pushpin', pushpin)
          this.map.entities.push(pushpin);

          this.onSelected.emit(this.loc)


        });
      },
      errorCallback: () => {
        console.log('error')
      }
    }

    Microsoft.Maps.loadModule('Microsoft.Maps.AutoSuggest', x);





  }


}
