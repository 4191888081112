
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IFoto } from 'app/models/perizia.interface';
import { ITire } from 'app/models/tire.interface';

@Component({
  selector: 'app-tire',
  templateUrl: './tire.component.html',
  styleUrls: ['./tire.component.css']
})
export class TireComponent implements OnInit {

  @Input() tire: ITire;
  @Input() title: string;
  @Input() kit: false;

  @Output() onChange: EventEmitter<any> = new EventEmitter();


  brands = ['Accelera', 'Achilles', 'Aeolus', 'Antares', 'Aoteli', 'A-Plus', 'Apollo', 'Atlas', 'Atturo', 'Autogrip', 'Autoguard', 'Avon', 'Barum', 'BF Goodrich', 'Blackstone', 'Bridgestone', 'Carbon', 'Ceat', 'Compass', 'Continental', 'Cooper', 'Debica', 'Delfin', 'Double Star', 'Dunlop', 'Duro', 'Event', 'Evergreen', 'Falken', 'Federal', 'Firestone', 'Fortuna', 'Fulda', 'Fullway', 'General Tire', 'Gerutti', 'Gislaved', 'Golden Tyre', 'Goodride', 'Goodyear', 'Gremax', 'Gripmax', 'GT Radial', 'Hankook', 'Heidenau', 'Hercules', 'Hifly', 'Imperial', 'Infinity', 'Insa Turbo', 'Jinyu Tire', 'Kelly', 'Kenda', 'Kings Tire', 'Kingstar', 'Kleber', 'Kormoran', 'Kumho', 'Landsail', 'Lassa', 'Laufenn', 'Linglong', 'Marangoni', 'Marshal', 'Mastersteel', 'Matador', 'Maxtrek', 'Maxxis', 'Meteor', 'Michelin', 'Mickey Thompson', 'Milestone', 'Minerva', 'Mirage', 'Nankang', 'Nexen', 'Nokian', 'Onyx', 'Ovation', 'Pace', 'Petlas', 'Pirelli', 'Platin', 'Pneumant', 'Premada', 'Regal', 'Respa', 'Rigdon', 'Riken', 'Roadstone', 'Rockstone', 'Rovelo', 'Sailun', 'Sava', 'Semperit', 'Silverstone', 'Sonar', 'Sportiva', 'Starfire', 'Starmaxx', 'Sumo Firenza', 'Sunitrac', 'Sunny', 'Suntek', 'Superia', 'Syron', 'Taurus', 'Tigar', 'Toledo', 'Torque', 'Toyo', 'Tracmax', 'Trayal', 'Trazano', 'Triangle', 'Tristar', 'T-Tyre', 'Uniroyal', 'Vee Rubber', 'Vredestein', 'Wanli', 'West Lake', 'Yokohama', 'Zeetex',];
  l = [105, 110, 115, 120, 125, 130, 135, 140, 145, 150, 155, 160, 165, 170, 175, 180, 185, 190, 195, 200, 205, 210, 215, 220, 225, 230, 235, 240, 245, 250, 255, 260, 265, 270, 275, 280, 285, 290, 295, 300, 305, 310, 315, 320, 325, 330, 335, 340, 345, 350, 355, 360, 365, 370, 375, 380, 385, 390, 395, 400,];
  h = [25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95];
  d = [10, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 22.5, 23, 24, 34, 36, 39, 40, 41, 45,];
  t = ['Invernali', 'Estive', 'Tutte le stagioni'];

  constructor() {

  }

  @Output() onOpenViewer: EventEmitter<any> = new EventEmitter();
  openViewer(event, img) {
    console.log('openViewer tire')
    this.onOpenViewer.emit({ i: 0, source: img, double: true })
  }

  ngOnInit() {
    console.log(this.tire, this.title)
    //if(!this.tire) this.tire = { l: null, h: null, d: null, brand: null, runflat: null, type: null, mm: null };
  }

  changed(e) {
    this.onChange.emit();
  }

  filterForGallery(list:IFoto[]) {
    return list.filter(e => e.img)
  }

}
