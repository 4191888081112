<div class="cnt card">

  <table class="light">
    <tr>
      <td colspan=4 style="color:black; text-align: center;">
        <strong>{{title}}</strong>
      </td>
    </tr>
  

    <tr *ngIf="!kit">

      <td colspan=4>
        {{tire?.type}} - Battistrada: {{tire?.mm}} mm
        <!--
        <ion-select [(ngModel)]="tire?.type" placeholder="Tipo" (ionChange)="changed($event)">
          <ion-select-option *ngFor="let e of t" [value]="e">{{e}}</ion-select-option>
        </ion-select>
        -->
      </td>

    </tr>

    <tr>
      <td colspan="4">
        <app-gallery *ngIf="tire?.img" [disabled]="true" [source]="filterForGallery(tire.img)" [(source)]="tire.img" (onSelected)=openViewer($event,tire.img) [uploader]="false" [categorized]="false" [showLabel]="false"> 
        </app-gallery>
      </td>
    </tr>

  </table>




</div>