<nav style="position: static;" class="navbar navbar-expand-lg  navbar-absolute " [hidden]="!user">
    <div class="container-fluid">


        <span style="float:left; margin-right: 30px"><img style="height:60px" src="/assets/img/logo.png"></span>

        <div class="basic-container" style="float:left">


            <span *ngFor="let item of navItems" style="margin-top:30px">
                <!-- Handle branch node buttons here -->
                <span *ngIf="item.children && item.children.length > 0">
                    <span mat-raised-button class="button-menu" [matMenuTriggerFor]="menu.childMenu">
                        <mat-icon class="my-icon">{{item.icon}}</mat-icon>
                        <span>{{item.name}}</span>
                        <mat-icon class="my-icon">expand_more</mat-icon>
                    </span>
                    <app-navbar-item #menu [items]="item.children"></app-navbar-item>
                </span>
                <!-- Leaf node buttons here -->
                <span *ngIf="!item.children || item.children.length === 0">
                    <span mat-button [routerLink]="item.path">
                        <mat-icon class="my-icon">{{item.icon}}</mat-icon>
                        {{item.name}}
                    </span>
                </span>
            </span>



        </div>



        <div class="collapse navbar-collapse justify-content-end" id="navigation">
            <ul class="navbar-nav">



                <span >
                    <!-- Handle branch node buttons here -->
                    <span>
                        <span mat-raised-button class="button-menu" [matMenuTriggerFor]="menu.childMenu">
                            <mat-icon class="my-icon">person</mat-icon>
                            <span>{{user?.Nome}} {{user?.Cognome}}</span>
                            <mat-icon class="my-icon">expand_more</mat-icon>
                        </span>
                        <app-navbar-item #menu [items]="uItems"></app-navbar-item>
                    </span>

                </span>

                <!--


                <li class="nav-item dropdown">
                    <a class="nav-link" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                        <i class="material-icons">person</i> {{user?.Nome}} {{user?.Cognome}}
                        <p>
                            <span class="d-lg-none d-md-block">Account</span>
                        </p>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
                        <a class="dropdown-item" (click)="changePwd()">Cambia password</a>
                        <a class="dropdown-item" (click)="logout()">Log-out</a>

                    </div>
                </li>


                <li class="nav-item dropdown">
                    <a class="nav-link" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
                        aria-expanded="false">
                        <i class="material-icons">notifications</i>
                        <span class="notification" *ngIf="notifies>0">{{notifies}}</span>
                        <p>
                            <span class="d-lg-none d-md-block">Some Actions</span>
                        </p>
                    </a>

                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink"
                        style="width:220px!important;">
                        <div *ngFor="let i of notifiesMenu">
                            <a class="dropdown-item" (click)="openUrl(i.link)" *ngIf="i.link"
                                style=" white-space: normal!important; padding:0; margin:5px;">{{i.title}}</a>
                            <span class="dropdown-item" *ngIf="!i.link">{{i.title}}</span>
                        </div>

                    </div>

                </li>
                -->

            </ul>
        </div>


    </div>
</nav>