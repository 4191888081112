import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import * as PhotoSphereViewer from 'photo-sphere-viewer/dist/photo-sphere-viewer.js'
@Component({
  selector: 'app-c360',
  templateUrl: './c360.component.html',
  styleUrls: ['./c360.component.scss']
})
export class C360Component implements OnInit, OnDestroy {
  viewer;

  _url;


  @Input() //value: IResult
  set setURL(url: string) {

    if (url) {
      this._url = url + '&360=1';
    }

    console.log('seturl360 ' + this._url)
    this.viewer = null;
    this.ngOnInit();
  }

  _images;
  @Input()
  set setImages(images) {

    this._images = images;

    console.log('setImages ', this._images)

  }




  constructor() { }

  ngOnInit() {

    setTimeout(() => {

      try {

        console.log(this._images)

        let cnt = document.querySelector('#viewer');
        cnt.innerHTML = '';

        let cnt_cruscotto;
        try {
          if (!this._images[7].url.endsWith('null'))
            cnt_cruscotto = '<img style="width:100%" src="' + this._images[7].url + '" >';
        } catch (error) { console.error(error) }

        let cnt_sedile_guida;
        try {
          if (!this._images[8].url.endsWith('null'))
            cnt_sedile_guida = '<img style="width:100%" src="' + this._images[8].url + '" >';
        } catch (error) { console.error(error) }

        let cnt_sedile_pass;
        try {
          if (!this._images[9].url.endsWith('null'))
            cnt_sedile_pass = '<img style="width:100%" src="' + this._images[9].url + '" >';
        } catch (error) { console.error(error) }

        let cnt_sedile_post;
        try {
          if (!this._images[10].url.endsWith('null'))
            cnt_sedile_post = '<img style="width:100%" src="' + this._images[10].url + '" >';
        } catch (error) { console.error(error) }

        this.viewer = new PhotoSphereViewer({
          container: cnt,
          panorama: this._url,

          markers: [


            /*
            {
              "id": 295,
              "circle": 10,
              "x": 3007,
              "y": 2171,
              tooltip: 'Cruscotto',
              svgStyle: {
                fill: 'rgba(200, 0, 0, 0.8)'
              },
              content: cnt_cruscotto
            }, {
              "id": 692,
              "circle": 10,
              "x": 2676,
              "y": 2894,
              tooltip: 'Sedile lato guida',
              svgStyle: {
                fill: 'rgba(200, 0, 0, 0.8)'
              },
              content: cnt_sedile_guida
            }, {
              "id": 712,
              "circle": 10,
              "x": 4555,
              "y": 2910,
              tooltip: 'Sedile lato passeggero',
              svgStyle: {
                fill: 'rgba(200, 0, 0, 0.8)'
              },
              content: cnt_sedile_pass
            }, {
              "id": 312,
              "circle": 10,
              "x": 7056,
              "y": 2925,
              tooltip: 'Sedile posteriore',
              svgStyle: {
                fill: 'rgba(200, 0, 0, 0.8)'
              },
              content: cnt_sedile_post
            }

            */
          ]


        });


        //this.hud = new PSVHUD(this.viewer)

        this.viewer.on('ready', () => {
          /*
                    this.viewer.rotate({
                      x: 1500,
                      y: 1000
                    });
          */
        });

        this.viewer.on('dblclick', event => {
          //console.log(event)

          let m = {
            id: Math.round(Math.random() * 1000),
            circle: 10,
            x: event.textureX,
            y: event.textureY,
          }

          console.log(JSON.stringify(m))

          this.viewer.hud.addMarker(m, true)

        })

      } catch (error) {
        console.error(error)
      }

    }, 100);

  }

  ngOnDestroy() {
    this.viewer = null
  }

}
