import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MD5 } from 'app/shared';
import { reject } from 'q';

@Injectable()
export class OsmService {

  constructor(private http: HttpClient) { }

  nominatim(country: string, county: string, cap: string, city: string, street: string):Promise<any> {


    let url = `https://nominatim.openstreetmap.org/search?format=json&polygon=1&country=${country}&county=${county}&city=${city}&street=${street}`;
    let item = localStorage.getItem('nominatim_' + MD5(url));

    if (item) {
      let item1 = JSON.parse(item);
      if (item1[0]) return new Promise(resolve => {
        resolve(item1)
      })

    }

    return new Promise((resolve,reject) => {

      this.http.get(url).toPromise().then(res => {

        console.log(res)

        if (!res||!res[0]) {
          console.warn('Impossibile geolocalizzare ' + county + ', ' + cap + ', ' + city + ', ' + street);
          console.warn(url.replace(' ','%20').replace(' ','%20').replace(' ','%20').replace(' ','%20').replace(' ','%20').replace(' ','%20').replace(' ','%20').replace(' ','%20'));
          console.warn(res);
          resolve(undefined);
        }


        localStorage.setItem('nominatim_' + MD5(url), JSON.stringify(res));

        let item = localStorage.getItem('nominatim_' + MD5(url));

        resolve(JSON.parse(item));


      })

    })





  }

}
