import { IChat } from './../components/chat/models/chat.interface';
import { IPerizia, IResult, IPeriziaReminder, ILiveXpertTime, IFile } from './../models/perizia.interface';
import { IUtente } from '../models/utente.interface';
import { IAnagrafica } from '../models/anagrafica.interface';
import { Router } from '@angular/router';
import { Injectable, Inject, LOCALE_ID } from '@angular/core';
import { HttpClient, HttpRequest, HttpHeaders } from '@angular/common/http';


import { UserService } from './user.service';
import { Observable } from 'rxjs';
import { IActivity } from 'app/models/activity.interface';
import { ITimeMatrix } from 'app/models/time-matrix.interface';
import { IForm } from 'app/models/form.interface';
import { ICommessa } from 'app/models/commessa.interface';
import { getEmailDomain } from 'app/shared';
import { ITempario } from 'app/models/tempario-interface';
import { DatePipe } from '@angular/common';
import { ITravel } from 'app/models/travel.interface';


@Injectable()
export class MysqlService {

  URL_BASE = '';
  stati_perizia: any[];

  isTest() {
    return this.URL_BASE.includes('-test');
  }

  getLogo() {
    let  logo = '/assets/img/logo.png'
    return logo;
  }

  getIcon() {
    return 'p';
  }

  constructor(private http: HttpClient, router: Router, private userService: UserService, @Inject(LOCALE_ID) private _locale: string) {

    this.URL_BASE = window.location.href.split('/').slice(0, 3).join('/');
    let host = window.location.href.split('/')[2].split(':')[0];

    if (host == 'localhost' || host.startsWith('192.168.')) {
      this.URL_BASE = 'https://program.ggroup.cloud/'
      this.URL_BASE += 'api/api.php?cmd=';
    } else {
      this.URL_BASE = window.location.href.split('/').slice(0, 3).join('/');
      this.URL_BASE += '/api/api.php?cmd=';
    }

    console.log('list stati perizia')
    this.http.get(this.URL_BASE + 'list_perizia_stati').toPromise()
      .then((res: any[]) => {
        this.stati_perizia = res
        localStorage.setItem('stati_perizia', JSON.stringify(res))
      })
      .catch(err => console.error(err))
  }




  perizie_checks_list(): Promise<any> {

    let data = {
      auth: this.userService.getCurrentUser(),
    }

    return this.http.post(this.URL_BASE + 'perizie_checks_list', data).toPromise();
  }


  conti_mark_as_invoiced(ids: any[], type: string, rif: string): Promise<any> {

    let data = {
      auth: this.userService.getCurrentUser(),
      ids: ids,
      type: type,
      rif: rif
    }

    return this.http.post(this.URL_BASE + 'conti_mark_as_invoiced', data).toPromise();
  }

  conti_list(id: number, cliente: number, commessa: number, targa: string, dtaStart1, dtaStart2, dtaEnd1, dtaEnd2, assegnatoA1: string, assegnatoA2: string): Promise<any> {

    let data = {
      auth: this.userService.getCurrentUser(),
      id: id,
      cliente: cliente,
      commessa: commessa,
      targa: targa,
      dtaStart1: dtaStart1,
      dtaStart2: dtaStart2,
      dtaEnd1: dtaEnd1,
      dtaEnd2: dtaEnd2,
      assegnatoA1: assegnatoA1,
      assegnatoA2: assegnatoA2
    }

    return this.http.post(this.URL_BASE + 'conti_list', data).toPromise();
  }

  aws_chime_get_data(meetingId: string): Promise<any> {

    let data = {
      auth: this.userService.getCurrentUser(),
      meetingId: meetingId
    }

    return this.http.post(this.URL_BASE + 'aws_chime_get_data', data).toPromise();
  }

  aws_chime_add_question(meetingId: string, domanda: string): Promise<any> {

    let data = {
      auth: this.userService.getCurrentUser(),
      meetingId: meetingId,
      domanda: domanda
    }

    return this.http.post(this.URL_BASE + 'aws_chime_add_question', data).toPromise();
  }

  aws_chime_add_answer(meetingId: string, domandaId: number, attendeeId: string, val: string, username: string): Promise<any> {

    let data = {
      auth: this.userService.getCurrentUser(),
      meetingId: meetingId,
      domandaId: domandaId,
      attendeeId: attendeeId,
      val: val,
      username: username,
      date: new Date()
    }

    return this.http.post(this.URL_BASE + 'aws_chime_add_answer', data).toPromise();
  }


  aws_chime_list(): Promise<any> {

    let data = {
      auth: this.userService.getCurrentUser(),
    }

    return this.http.post(this.URL_BASE + 'aws_chime_list', data).toPromise();
  }


  aws_chime_create_meeting(): Promise<any> {

    let data = {
      auth: this.userService.getCurrentUser(),
    }

    return this.http.post(this.URL_BASE + 'aws_chime_create_meeting', data).toPromise();
  }

  aws_chime_get_meeting(meetingId: string): Promise<any> {

    let data = {
      auth: this.userService.getCurrentUser(),
      meetingId: meetingId
    }

    return this.http.post(this.URL_BASE + 'aws_chime_get_meeting', data).toPromise();
  }

  aws_chime_get_attendee(meetingId: string, attendeeId: string): Promise<any> {

    let data = {
      auth: this.userService.getCurrentUser(),
      meetingId: meetingId,
      attendeeId: attendeeId
    }

    return this.http.post(this.URL_BASE + 'aws_chime_get_attendee', data).toPromise();
  }

  aws_chime_create_attendee(meetingId: string): Promise<any> {

    let data = {
      auth: this.userService.getCurrentUser(),
      meetingId: meetingId
    }

    return this.http.post(this.URL_BASE + 'aws_chime_create_attendee', data).toPromise();
  }

  aws_chime_update_description(meetingId, description): Promise<any> {

    let data = {
      auth: this.userService.getCurrentUser(),
      meetingId: meetingId,
      description: description
    }

    return this.http.post(this.URL_BASE + 'aws_chime_update_description', data).toPromise();

  }

  aws_chime_update_allegati(meetingId, allegati): Promise<any> {

    let data = {
      auth: this.userService.getCurrentUser(),
      meetingId: meetingId,
      allegati: allegati
    }

    return this.http.post(this.URL_BASE + 'aws_chime_update_allegati', data).toPromise();

  }

  aws_chime_update_quiz(meetingId, quiz): Promise<any> {

    let data = {
      auth: this.userService.getCurrentUser(),
      meetingId: meetingId,
      quiz: quiz
    }

    return this.http.post(this.URL_BASE + 'aws_chime_update_quiz', data).toPromise();

  }

  aws_chime_update_quiz_response(meetingId, quiz, status): Promise<any> {

    let data = {
      auth: this.userService.getCurrentUser(),
      meetingId: meetingId,
      quiz: quiz,
      status: status
    }

    return this.http.post(this.URL_BASE + 'aws_chime_update_quiz_response', data).toPromise();

  }

  aws_chime_get_quiz(meetingId): Promise<any> {

    let data = {
      auth: this.userService.getCurrentUser(),
      meetingId: meetingId,
    }

    return this.http.post(this.URL_BASE + 'aws_chime_get_quiz', data).toPromise();

  }

  aws_chime_get_allegati(meetingId): Promise<any> {

    let data = {
      auth: this.userService.getCurrentUser(),
      meetingId: meetingId,
    }

    return this.http.post(this.URL_BASE + 'aws_chime_get_allegati', data).toPromise();

  }

  aws_chime_update_doc(meetingId, doc): Promise<any> {

    let data = {
      auth: this.userService.getCurrentUser(),
      meetingId: meetingId,
      doc: doc
    }

    return this.http.post(this.URL_BASE + 'aws_chime_update_doc', data).toPromise();

  }

  aws_chime_get_creator(meetingId): Promise<any> {

    let data = {
      auth: this.userService.getCurrentUser(),
      meetingId: meetingId,
    }

    return this.http.post(this.URL_BASE + 'aws_chime_get_creator', data).toPromise();

  }


  aws_chime_get_doc(meetingId): Promise<any> {

    let data = {
      auth: this.userService.getCurrentUser(),
      meetingId: meetingId,
    }

    return this.http.post(this.URL_BASE + 'aws_chime_get_doc', data).toPromise();

  }


  aws_chime_attendee_name_list(meetingId): Promise<any> {

    let data = {
      auth: this.userService.getCurrentUser(),
      meetingId: meetingId,
    }


    return this.http.post(this.URL_BASE + 'aws_chime_attendee_name_list', data).toPromise();

  }


  aws_chime_attendee_name_upsert(item): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      item: item
    }

    return this.http.post(this.URL_BASE + 'aws_chime_attendee_name_upsert', data).toPromise();
  }



  send_mail(msg): Promise<any> {

    let data = {
      auth: this.userService.getCurrentUser(),
      data: msg
    }

    return this.http.post(this.URL_BASE + 'send_mail', data).toPromise();
  }

  aws_rekognition_compare_faces(img1, img2) {

    let data = {
      auth: this.userService.getCurrentUser(),
      img1: img1,
      img2: img2
    }

    return this.http.post(this.URL_BASE + 'aws_rekognition_compare_faces', data).toPromise();

  }


  tinyurl_get_tiny_url(url: string): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      url: url
    }

    return this.http.post(this.URL_BASE + 'tinyurl_get_tiny_url', data).toPromise();

  }

  impostazioni_get(code): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      code: code
    }

    return this.http.post(this.URL_BASE + 'impostazioni_get', data).toPromise();
  }

  impostazioni_upsert(codice, valore): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      codice: codice,
      valore: valore
    }

    return this.http.post(this.URL_BASE + 'impostazioni_upsert', data).toPromise();
  }


  //////////////////////////////// commesse ////////////////////////////////////////


  commesse_list(): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
    }

    // console.log(data)

    return this.http.post(this.URL_BASE + 'commesse_list', data).toPromise();
  }

  commesse_list_by_customer(customer): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      customer: customer
    }

    // console.log(data)

    return this.http.post(this.URL_BASE + 'commesse_list_by_customer', data).toPromise();
  }

  commesse_list_current(tipo, cliente): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      tipo: tipo,
      cliente: cliente
    }

    console.log('commesse - data', data)

    return this.http.post(this.URL_BASE + 'commesse_list_current', data).toPromise();
  }

  commessa_get_attachment_url(id: number, f: IFile, type: string, $fout = '') {
    return `${this.URL_BASE}commesse_get_attachment&id=${id}&hash=${f.hash}&filename=${f.fileName}&type=${type}`;
  }

  commesse_upsert(item: ICommessa): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      item: item
    }

    return this.http.post(this.URL_BASE + 'commesse_upsert', data).toPromise();
  }

  commesse_get(id: number): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id
    }

    return this.http.post(this.URL_BASE + 'commesse_get', data).toPromise();
  }

  commesse_delete(id: number): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id
    }

    console.log(data)

    return this.http.post(this.URL_BASE + 'commesse_delete', data).toPromise();
  }

  ////////////////////////// inspector_groups_ ///////////////////

  inspector_groups_list_groups(): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
    }

    return this.http.post(this.URL_BASE + 'inspector_groups_list_groups', data).toPromise();
  }

  inspector_groups_list_users(): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
    }

    return this.http.post(this.URL_BASE + 'inspector_groups_list_users', data).toPromise();
  }


  inspector_groups_list_users_by_group(group: string): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      group: group
    }

    return this.http.post(this.URL_BASE + 'inspector_groups_list_users_by_group', data).toPromise();
  }

  inspector_groups_list_groups_by_user(user: string): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      user: user
    }

    return this.http.post(this.URL_BASE + 'inspector_groups_list_groups_by_user', data).toPromise();
  }

  inspector_groups_get_user_group_location(zone: string, group: string): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      zone: zone,
      group: group
    }

    return this.http.post(this.URL_BASE + 'inspector_groups_get_user_group_location', data).toPromise();
  }


  inspector_groups_add_group(group: string): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      group: group
    }

    return this.http.post(this.URL_BASE + 'inspector_groups_add_group', data).toPromise();
  }



  inspector_groups_upsert(item): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      item: item
    }

    return this.http.post(this.URL_BASE + 'inspector_groups_upsert', data).toPromise();
  }


  ////////////////////////// CESPITI ///////////////////////////


  notifyWreck(targa: string, id: number): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      targa: targa,
      id: id
    }

    return this.http.post(this.URL_BASE + 'notifyWreck', data).toPromise();
  }

  ////////////////////////// CHECKS ///////////////////////////

  checks_list(): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
    }

    return this.http.post(this.URL_BASE + 'checks_list', data).toPromise();
  }


  checks_get(id): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id
    }
    return this.http.post(this.URL_BASE + 'checks_get', data).toPromise();
  }


  checks_upsert(item: any): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      item: item
    }
    return this.http.post(this.URL_BASE + 'checks_upsert', data).toPromise();
  }


  checks_delete(id: number): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id
    }

    console.log(data)

    return this.http.post(this.URL_BASE + 'checks_delete', data).toPromise();
  }


  ///////////////////////////////////////////////////////////////////////////////////////////

  forms_save_value(id, value): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id,
      value: value
    }

    // console.log(data)

    return this.http.post(this.URL_BASE + 'forms_save_value', data).toPromise();
  }

  forms_get_print_url(id, hash) {
    return this.URL_BASE + `forms_print&token=${this.userService.getToken()}&email=${this.userService.getEmail()}&id=${id}&hash=${hash}`;
  }

  forms_list(): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
    }

    // console.log(data)

    return this.http.post(this.URL_BASE + 'forms_list', data).toPromise();
  }

  forms_list_cc(): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
    }

    return this.http.post(this.URL_BASE + 'forms_list_cc', data).toPromise();
  }

  forms_list_mod(): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
    }

    return this.http.post(this.URL_BASE + 'forms_list_mod', data).toPromise();
  }

  forms_upsert(item: IForm): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      item: item
    }

    return this.http.post(this.URL_BASE + 'forms_upsert', data).toPromise();
  }

  forms_delete(id: number): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id
    }

    console.log(data)

    return this.http.post(this.URL_BASE + 'forms_delete', data).toPromise();
  }

  forms_get(id: number): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id
    }

    console.log(data)

    return this.http.post(this.URL_BASE + 'forms_get', data).toPromise();

  }

  forms_get_by_code(code: string): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      code: code
    }

    console.log(data)

    return this.http.post(this.URL_BASE + 'forms_get_by_code', data).toPromise();

  }




  /////////////////// TIME MATRIX ////////////////////////

  time_matrix_list(): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
    }

    // console.log(data)

    return this.http.post(this.URL_BASE + 'time_matrix_list', data).toPromise();
  }

  time_matrix_upsert(item: ITimeMatrix): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      item: item
    }

    return this.http.post(this.URL_BASE + 'time_matrix_upsert', data).toPromise();
  }

  time_matrix_delete(id: number): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id
    }

    console.log(data)

    return this.http.post(this.URL_BASE + 'time_matrix_delete', data).toPromise();
  }


  time_matrix_get_time_required(activity: string, subactivity: string, customer: string, commessa: string): Promise<any> {

    let data = {
      auth: this.userService.getCurrentUser(),
      activity: !activity ? '' : activity,
      subactivity: !subactivity ? '' : subactivity.replace('perizie', 'insurance'),
      customer: !customer ? 0 : customer,
      commessa: !commessa ? 0 : commessa
    }

    console.log(data)

    return this.http.post(this.URL_BASE + 'time_matrix_get_time_required', data).toPromise();
  }


  /////////////////////////////////////////////////////////////////////


  /////////////////// TEMPARIO ////////////////////////

  tempario_list(): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
    }

    // console.log(data)

    return this.http.post(this.URL_BASE + 'tempario_list', data).toPromise();
  }

  tempario_upsert(item: ITempario): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      item: item
    }

    return this.http.post(this.URL_BASE + 'tempario_upsert', data).toPromise();
  }

  tempario_delete(id: number): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id
    }

    console.log(data)

    return this.http.post(this.URL_BASE + 'tempario_delete', data).toPromise();
  }






  /////////////////////////////////////////////////////////////////



  ////////////////////// BING MAPS ////////////////////////////////

  bingmaps_optimize(request) {

    let data = {
      auth: this.userService.getCurrentUser(),
      request: request
    }

    return this.http.post(this.URL_BASE + 'bingmaps_optimize', data).toPromise()

  }

  bingmaps_get_nearest_inspector(group, lat, lon): Promise<any> {

    let data = {
      auth: this.userService.getCurrentUser(),
      group: group,
      origin: {
        latitude: lat,
        longitude: lon
      }
    }

    return this.http.post(this.URL_BASE + 'bingmaps_get_nearest_inspector', data).toPromise()

  }

  bingmaps_search(query: string) {

    let data = {
      auth: this.userService.getCurrentUser(),
      query: query
    }


    return this.http.post(this.URL_BASE + 'bingmaps_search', data).toPromise()

  }

  bingmaps_reverse_search(location) {

    let data = {
      auth: this.userService.getCurrentUser(),
      location: location
    }

    console.log('data', data)

    return this.http.post(this.URL_BASE + 'bingmaps_reverse_search', data).toPromise()

  }

  bingmaps_optimize_get_result(callbackUrl: string) {

    return this.http.get(callbackUrl).toPromise()

  }

  /////////////////////////////////////////////////////////////////

  ///////////////////////// SMS ///////////////////////////////////
  sms_send(dest: string, msg: string, send: string, return_id = 0): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      dest: dest.replace('+', ''),
      msg: msg,
      send: send,
      return_id: return_id
    }

    return this.http.post(this.URL_BASE + 'sms_send', data).toPromise();
  }

  sms_get_status(id: string): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id
    }

    return this.http.post(this.URL_BASE + 'sms_get_status', data).toPromise();
  }




  /////////////////////////////////////////////////////////////////////////////////////////

  global_get_all_times() {
    let data = {
      auth: this.userService.getCurrentUser(),
    }

    return this.http.post(this.URL_BASE + 'global_get_all_times', data).toPromise();
  }

  global_list_times(dbname: string) {
    let data = {
      auth: this.userService.getCurrentUser(),
      dbname: dbname
    }

    return this.http.post(this.URL_BASE + 'global_list_times', data).toPromise();
  }
  ///////////////////////////////////////////////////////////////////////////////////////////

  
  calendar_upsert_travel(item: ITravel): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      item: item
    }

    // console.log(data)

    return this.http.post(this.URL_BASE + 'calendar_upsert_travel', data).toPromise();
  }

  calendar_list_travel(agent, dta): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      agent: agent,
      d1: dta,
      allmonth: true
    }

    // console.log(data)

    return this.http.post(this.URL_BASE + 'calendar_list_travel', data).toPromise();
  }

  calendar_list(type, noLoc = false): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      type: type,
      noLoc: noLoc
    }

    // console.log(data)

    return this.http.post(this.URL_BASE + 'calendar_list', data).toPromise();
  }

  calendar_get_next(email): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      email: email
    }

    // console.log(data)

    return this.http.post(this.URL_BASE + 'calendar_get_next', data).toPromise();
  }

  ///////////////////////////////////////////////////////////////////////////////////////////


  rel_activity_user_list(): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
    }

    // console.log(data)

    return this.http.post(this.URL_BASE + 'rel_activity_user_list', data).toPromise();
  }

  rel_activity_user_upsert(item: IActivity): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      item: item
    }

    return this.http.post(this.URL_BASE + 'rel_activity_user_upsert', data).toPromise();
  }

  rel_activity_user_delete(id: number): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id
    }

    console.log(data)

    return this.http.post(this.URL_BASE + 'rel_activity_user_delete', data).toPromise();
  }

  ///////////////////////////////////////////////////////////////////////////////////////////


  activity_list(): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
    }

    // console.log(data)

    return this.http.post(this.URL_BASE + 'activity_list', data).toPromise();
  }

  activity_upsert(item: IActivity): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      item: item
    }

    return this.http.post(this.URL_BASE + 'activity_upsert', data).toPromise();
  }

  activity_delete(id: number): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id
    }

    console.log(data)

    return this.http.post(this.URL_BASE + 'activity_delete', data).toPromise();
  }

  ///////////////////////////////////////////////////////////////////////////////////////////


  etax_get_valutazione(codice_eurotax: string, tipo_veicolo: string, anno, mese, km) {

    let data = {
      auth: this.userService.getCurrentUser(),
      codice_eurotax: codice_eurotax,
      tipo_veicolo: tipo_veicolo,
      anno: anno,
      mese: mese,
      km: km
    }

    return this.http.post(this.URL_BASE + 'etax_get_valutazione', data).toPromise();
  }

  etax_search_plate(plate: string) {

    let data = {
      auth: this.userService.getCurrentUser(),
      plate: plate
    }

    return this.http.post(this.URL_BASE + 'etax_search_plate', data).toPromise();
  }

  etax_list_marche(tipo): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      tipo_veicolo: tipo
    }

    return this.http.post(this.URL_BASE + 'etax_list_marche', data).toPromise();
  }

  etax_list_anni(marca: string): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      marca: marca
    }

    return this.http.post(this.URL_BASE + 'etax_list_anni', data).toPromise();
  }

  etax_list_modelli(marca: string, anno: string, tipo_veicolo: string): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      marca: marca,
      anno: anno,
      tipo_veicolo: tipo_veicolo
    }

    return this.http.post(this.URL_BASE + 'etax_list_modelli', data).toPromise();
  }

  etax_list_versioni(marca: string, anno: string, modello: string, tipo_veicolo: string): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      marca: marca,
      anno: anno,
      modello: modello,
      tipo_veicolo: tipo_veicolo
    }

    return this.http.post(this.URL_BASE + 'etax_list_versioni', data).toPromise();
  }

  ///////////////////////////////////////////////////////////////////////////////////////////

  list_province(): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser()
    }

    return this.http.post(this.URL_BASE + 'list_province', data).toPromise();
  }

  list_comuni(prov: string): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      prov: prov
    }

    return this.http.post(this.URL_BASE + 'list_comuni', data).toPromise();
  }

  ///////////////////////////////////////////////////////////////////////////////////////////

  get_print_adz(id: number, download: boolean) {
    return this.URL_BASE + 'print_adz&header=' + this.getIcon() + '&id=' + id + (download ? '&download=1' : '');
  }

  get_print_adz_perizie(id: number, download: boolean, hash: string, header = this.getIcon()) {
    return this.URL_BASE + 'print_adz_perizie&header=' + header + '&id=' + id + '&hash=' + hash + (download ? '&download=1' : '');
  }

  get_export_url() {
    return this.URL_BASE + 'export';
  }

  get_exp_tab1_url(id: number) {
    return this.URL_BASE + 'exp_tab1&id=' + id;
  }

  get_print_tab1_url(id: number) {
    return this.URL_BASE + 'print_tab1&id=' + id;
  }


  //////////////////////////////////////////////////////////////////////////////////////////

  perzie_adz_update_field(idPerizia: number, field: string, value: any) {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: idPerizia,
      field: field,
      value: value
    }

    return this.http.post(this.URL_BASE + 'perzie_adz_update_field', data).toPromise();

  }

  perizia_convert_input_file(file: IFile): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      file: file
    }

    return this.http.post(this.URL_BASE + 'perizia_convert_input_file', data).toPromise();
  }

  perizia_convert_concordato_file(sel, dest, importo, id): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      sel: sel,
      dest: dest,
      importo: importo,
      id: id
    }

    return this.http.post(this.URL_BASE + 'perizia_convert_concordato_file', data).toPromise();
  }

  perizie_download_inter(user: string, dta: Date, description: string) {

    let p = new DatePipe('it-it')
    let d = p.transform(dta, 'yyyy-MM-dd')

    return this.URL_BASE + `perizie_download_inter&token=${this.userService.getToken()}&email=${this.userService.getEmail()}&header=${this.getIcon()}&logo=${this.getLogo()}&user=${user}&dta=${d}&description=${description}`;

  }

  perizie_list_adz(id) {
    return `${this.URL_BASE}perizie_list_adz&id=${id}&token=${this.userService.getToken()}&email=${this.userService.getEmail()}`;
  }

  perizie_claim_nr_exists(claimNr: string, id: number, cliente: number) {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id,
      claimNr: claimNr,
      cliente: cliente
    }

    return this.http.post(this.URL_BASE + 'perizie_claim_nr_exists', data).toPromise();

  }

  perizia_upsert_result(id: number, result: IResult): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id,
      result: result
    }

    return this.http.post(this.URL_BASE + 'perizia_upsert_result', data).toPromise();

  }

  perizia_upsert_proposte(id: number, proposte, iban_proposta): Promise<any> {
    let data = {
      id: id,
      proposte: proposte,
      iban_proposta: iban_proposta
    }

    return this.http.post(this.URL_BASE + 'perizia_upsert_proposte', data).toPromise();

  }

  perizia_blur(id: number, opts: any): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id,
      opts: opts
    }

    return this.http.post(this.URL_BASE + 'perizia_blur', data).toPromise();

  }


  perizie_reminder_list(filtroUtente, idPerizia = -1): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: idPerizia,
      filtroUtente: filtroUtente
    }

    return this.http.post(this.URL_BASE + 'perizie_reminder_list', data).toPromise();
  }

  perizie_reminder_upsert(item: IPeriziaReminder): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      item: item
    }

    return this.http.post(this.URL_BASE + 'perizie_reminder_upsert', data).toPromise();
  }

  perizie_reminder_delete(id): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id
    }

    return this.http.post(this.URL_BASE + 'perizie_reminder_delete', data).toPromise();
  }


  async perizie_send_parcel(item: IPerizia, urls: any[]): Promise<any> {

    let sender = '';
    await this.get_current_user_profile().then(res => {

      console.log(res[0])

      sender = res[0].Email2

    })


    let recipient = '';
    let cc = `${sender};s.iannone` + getEmailDomain(this.getIcon()); // fix multidominio per spostare drivenow su gg

    if (!this.isTest()) {
      recipient = 'repair.south@share-now.com; damagehandling@drive-now.com';
    }

    let bcc = [] //'m.bombardi@t-econsulting.it';

    let data = {
      auth: this.userService.getCurrentUser(),
      id: item.id,
      targa: item.targa,
      nsinistro: item.sinistro_numero,
      recipient: recipient,
      cc: cc,
      bcc: bcc,
      urls: urls
    }

    console.log(data)

    return this.http.post(this.URL_BASE + 'perizie_send_parcel', data).toPromise();
  }


  get_export_url_perizie() {
    return this.URL_BASE + `export_perizie&token=${this.userService.getToken()}&email=${this.userService.getEmail()}`;
  }



  get_perizia_result_image_url(img: string) {
    return `${this.URL_BASE}get_perizia_result_image_url&img=${img}`;
  }

  import_perizie_xls(file: IFile): Promise<any> {
    
    let data = {
      auth: this.userService.getCurrentUser(),
      file: file
    }

    return this.http.post(this.URL_BASE + 'import_perizie_xls', data).toPromise();
  }

  get_costi_per_parte(vehType: string, partCode: string): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      vehType: vehType,
      partCode: partCode
    }

    return this.http.post(this.URL_BASE + 'get_get_costi_per_parte', data).toPromise();

  }

  get_perizia(id: number, type: string, hash = ''): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id,
      type: type,
      hash: hash
    }

    return this.http.post(this.URL_BASE + 'get_perizia', data).toPromise();
  }

  perizie_auto_assign(id: number): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id,
    }

    console.log(data)

    return this.http.post(this.URL_BASE + 'perizie_auto_assign', data).toPromise();
  }

  perizie_manual_assign(id: number, assegnatoA1: string, assegnatoA2: string, riferimento: string): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id,
      assegnatoA1: assegnatoA1,
      assegnatoA2: assegnatoA2,
      riferimento: riferimento,
    }

    console.log(data)

    return this.http.post(this.URL_BASE + 'perizie_manual_assign', data).toPromise();
  }

  get_perizia_result(id: number): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id
    }

    return this.http.post(this.URL_BASE + 'get_perizia_result', data).toPromise();
  }

  get_perizia_attachment_url(id: number, f: IFile, type: string, $fout = '') {
    return `${this.URL_BASE}get_perizia_attachment&id=${id}&hash=${f.hash}&filename=${f.fileName}&type=${type}`;
  }

  get_perizia_zip_url(id: number, type: string, header = this.getIcon()) {

    if (type == 'noval') type = 'pdf'

    return `${this.URL_BASE}get_perizia_zip_url&id=${id}&token=${this.userService.getToken()}&email=${this.userService.getEmail()}&type=${type}&header=${header}`;
  }

  get_perizia_to_adz_url(id: number) {
    return `${this.URL_BASE}perizia_to_adz&id=${id}&token=${this.userService.getToken()}&email=${this.userService.getEmail()}`;
  }

  list_perizie(type: string, ids = '', limit = 0, stato = ''): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      type: type,
      ids: ids,
      limit: limit,
      stato: stato
    }

    // console.log(data)

    return this.http.post(this.URL_BASE + 'list_perizie', data).toPromise();
  }

  list_storico_perizia(id: number): Promise<any> {
    return this.list_storico('perizie', id)
  }

  list_storico(table: string, id: number): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      table: table,
      id: id
    }

    return this.http.post(this.URL_BASE + 'list_storico', data).toPromise();
  }

  list_logs_perizia(id: number): Promise<any> {
    return this.list_logs('perizie', id)
  }

  list_logs(table: string, id: number): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      table: table,
      id: id
    }

    return this.http.post(this.URL_BASE + 'list_logs', data).toPromise();
  }

  upsert_perizia(item: IPerizia): Observable<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      item: item
    }


    const request = new HttpRequest('POST', this.URL_BASE + 'upsert_perizia', data, {
      reportProgress: true
    });



    return this.http.request(request);
  }

  upsert_perizia_accept_reject(id: number, nextStatus: string): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id,
      nextStatus: nextStatus
    }

    return this.http.post(this.URL_BASE + 'upsert_perizia_accept_reject', data).toPromise();
  }


  upsert_allegati_perizia(item: IPerizia): Observable<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      item: item
    }

    const request = new HttpRequest('POST', this.URL_BASE + 'upsert_allegati_perizia', data, {
      reportProgress: true
    });

    return this.http.request(request);
  }

  delete_perizia(id: number): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id
    }

    return this.http.post(this.URL_BASE + 'delete_perizia', data).toPromise();
  }


  perizie_update_appointments(dirties): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      data: dirties
    }

    return this.http.post(this.URL_BASE + 'perizie_update_appointments', data).toPromise();
  }

  //////////////////////////////////////////////////////////////////////////////////////////

  list_anagrafiche(type: string): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      type: type
    }

    // console.log(data)

    return this.http.post(this.URL_BASE + 'list_anagrafiche', data).toPromise();
  }

  get_anagrafica(id): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id
    }

    // console.log(data)

    return this.http.post(this.URL_BASE + 'get_anagrafica', data).toPromise();
  }

  upsert_anagrafica(item: IAnagrafica): Promise<IAnagrafica[]> {
    let data = {
      auth: this.userService.getCurrentUser(),
      item: item
    }

    return this.http.post<IAnagrafica[]>(this.URL_BASE + 'upsert_anagrafica', data).toPromise();
  }

  delete_anagrafica(id: number): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id
    }

    return this.http.post(this.URL_BASE + 'delete_anagrafica', data).toPromise();
  }


  //////////////////////////////////////////////////////////////////////////////////////////




  list_utenti(): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
    }

    return this.http.post(this.URL_BASE + 'list_utenti', data).toPromise();
  }

  list_user_by_type(type: string, d = undefined): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      type: type,
      dta: d
    }

    return this.http.post(this.URL_BASE + 'list_user_by_type', data).toPromise();
  }


  upsert_utente(item: IUtente): Promise<IAnagrafica[]> {
    let data = {
      auth: this.userService.getCurrentUser(),
      item: item
    }

    return this.http.post<IAnagrafica[]>(this.URL_BASE + 'upsert_utente', data).toPromise();
  }

  upsert_utente_geo(email, geo): Promise<IAnagrafica[]> {
    let data = {
      auth: this.userService.getCurrentUser(),
      email: email,
      geo: geo
    }

    return this.http.post<IAnagrafica[]>(this.URL_BASE + 'upsert_utente_geo', data).toPromise();
  }

  upsert_utente_zone(email, zones): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      email: email,
      zones: zones
    }

    return this.http.post<IAnagrafica[]>(this.URL_BASE + 'upsert_utente_zone', data).toPromise();
  }


  delete_utente(id: number): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      id: id
    }

    return this.http.post(this.URL_BASE + 'delete_utente', data).toPromise();
  }

  list_avvs(): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
    }

    return this.http.post(this.URL_BASE + 'list_avvs', data).toPromise();
  }

  get_access_history(dta): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      dta: dta
    }

    return this.http.post(this.URL_BASE + 'get_access_history', data).toPromise();

  }

  get_current_user_profile(login = false): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      login: login
    }

    return this.http.post(this.URL_BASE + 'get_current_user_profile', data).toPromise();

  }

  user_change_pwd_request(): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
    }


    return this.http.post(this.URL_BASE + 'user_change_pwd_request', data).toPromise();

  }

  user_change_pwd_confirm(hash: string, password: string): Promise<any> {
    let data = {
      token: hash,
      password: password
    }

    console.log(data)


    return this.http.post(this.URL_BASE + 'user_change_pwd_confirm', data).toPromise();

  }
  
  getListChat(code: string, email: string): Observable<IChat[]> {

    let data = {
      auth: this.userService.getCurrentUser(),
      code: code,
      email: email
    }

    return this.http.post<IChat[]>(this.URL_BASE + 'list-chat', data);

  }

  //// DEPRECATED ///
  getListChatNotifies(): Promise<any> {

    let data = {
      auth: this.userService.getCurrentUser()
    }

    return this.http.post<IChat[]>(this.URL_BASE + 'list-chat-notifies', data).toPromise();

  }

  getListNotifies(): Promise<any> {

    let data = {
      auth: this.userService.getCurrentUser()
    }

    return this.http.post<any[]>(this.URL_BASE + 'list-notifies', data).toPromise();

  }

  addChat(chat: IChat): Promise<any> {

    if (!chat.sender) chat.sender = this.userService.getCurrentUser().email;

    let data = {
      auth: this.userService.getCurrentUser(),
      data: chat
    }

    console.log(data)

    return this.http.post<IChat[]>(this.URL_BASE + 'add-chat', data).toPromise();

  }

  //////////////////////////////////////////////////////////////////////////////////////////

  login(email: string, password: string): Promise<any> {

    return this.http.post(this.URL_BASE + 'login', { email: email, password: password }).toPromise();

  }

  get_stats(): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser()
    }
    return this.http.post(this.URL_BASE + 'get_stats', data).toPromise();

  }
  
  list_appuntamenti(): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser()
    }
    return this.http.post(this.URL_BASE + 'list_appuntamenti', data).toPromise();

  }


  ////////////////////////////////////////////////////////////////////////////////////

  check_targa(targa: string, id: number): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      targa: targa,
      id: id
    }

    // console.log(data)

    return this.http.post(this.URL_BASE + 'check_targa', data).toPromise();
  }

  check_telaio(telaio: string, id: number): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
      telaio: telaio,
      id: id
    }

    // console.log(data)

    return this.http.post(this.URL_BASE + 'check_telaio', data).toPromise();
  }

  ////////////////////////////////////////////////////////////////////////////////////

  program_getData(): Promise<any> {
    let data = {
      auth: this.userService.getCurrentUser(),
    }

    return this.http.post(this.URL_BASE + 'program_getData', data).toPromise();
  }

  program_getUrl(id: number) {
    let url = this.URL_BASE + 'program_final_report&id=' + id;
    return url;
  }

  getStati() {
    return [
      'BOZZA',
      'APERTO',
      'CONCLUSO SOTTO FRANCHIGIA',
      'CONCLUSO CONTROPERIZIA',
      'CONCLUSO CONFORME',
    ];
  }


  getTipoPerizia(type: string) {

    if (type == 'ssu') return ['Stato d\'uso'];
    if (type == 'buyback') return ['Buyback'];
    if (type == 'tradein') return ['tradein'];

    return [
      'RC Auto',
      'Kasco collisione',
      'Fenomeni socio-politici',
      'Furto totale con ritrovamento',
      'Incendio e scoppio',
      'RC Prodotto',
      'Rivalse',
      'Globale fabbricati',
      'Danno merci',
      'Preassuntiva',
      'Kasco completa',
      'Atti vandalici',
      'Fenomeni naturali',
      'Furto parziale',
      'Cristalli',
      'RC Professionale',
      'RC Generale',
      'Acqua condotta',
      'Merci trasportate',
      'Pratica canalizzata',

      'Atto Vandalico',
      'ID2F',
      'Furto',
      'Altre RCA',
      'Collisione',
      'Kasco',
      'Eventi Naturali - Grandine',
      'Altri Eventi Naturali',

    ]
  }

  getStatiPerizia(): any[] {

    if (this.stati_perizia) {
      return this.stati_perizia;
    }

    if (!this.stati_perizia) {
      return JSON.parse(localStorage.getItem('stati_perizia'));
    }


  }


  getStatiIRepair(): any[] {
    return [
      { status: "Verifica pre-assicurativa", color: "yellow" },
      { status: "Autority sinistro", color: "red" },
      { status: "Valorizzazione degrado", color: "red" },
      { status: "Richiesta integrazione foto", color: "yellow" },
      { status: "Integrazione foto effettuata", color: "red" },
      { status: "nota da leggere", color: "red" },
      { status: "Richiesta preventivo incompleto", color: "red" },
      { status: "Preventivo incompleto bloccato", color: "red" },
      { status: "Preventivo incompleto sbloccato", color: "red" },
      { status: "Autorizzazione smontaggio", color: "red" },
      { status: "Realizzato smontaggio, nessuna integrazione", color: "red" },
      { status: "Preventivo in proposta", color: "red" },
      { status: "proposta accettata", color: "green" },
      { status: "proposta rifiutata", color: "red" }
    ]


  }

  getStato(sts, assegnatoASalaControllo = false) {

    //console.log('assegnatoASalaControllo' + assegnatoASalaControllo)
    //console.log('sts' + sts)
    let stati = this.getStatiPerizia();

    //mod per saltare passaggio sala controllo
    let sts1;
    if (sts == 'CONSEGNATO' && !assegnatoASalaControllo) sts1 = this.getStato('CONSEGNATO A SALA CONTROLLO');


    for (let i = 0; i < stati.length; i++) {
      if (sts == stati[i].sts) {

        let s = stati[i];

        if (sts1) {
          s.reject = sts1.reject;
          s.reject_sendMessageTo = sts1.reject_sendMessageTo;
        }

        return s
      }
    }

    return { competence: '' };
  }

  getStatiPeriziaAll(): string[] {
    let stati = this.getStatiPerizia();

    let r = [];
    for (let i = 0; i < stati.length; i++) {
      r.push(stati[i].sts);
    }

    return r;
  }

  getStatiPeriziaAll_client(): string[] {
    let stati = this.getStatiPerizia();

    let r = [];
    for (let i = 0; i < stati.length; i++) {
      r.push(stati[i].clientDescription);
    }

    r = r.filter((value, index, self) => {
      return self.indexOf(value) === index;
    });

    return r;
  }



  getPeriziaStatusFilter(): any[] {
    let r = [];


    let arr = []

    switch (this.userService.getCurrentUser().role) {
      case 'CLIENTE':
      case 'MANDANTE':
        arr = this.getStatiPeriziaAll_client();
        break;

      default:
        arr = this.getStatiPeriziaAll();
    }


    for (let e of arr) {
      r.push({ value: e, title: e })
    }
    return r;
  }


  getAppointments(): Appointment[] {
    return appointments;
  }


}

let appointments: Appointment[] = [
  {
    text: "Website Re-Design Plan",
    startDate: new Date(2017, 4, 22, 9, 30),
    endDate: new Date(2017, 4, 22, 11, 30)
  }, {
    text: "Book Flights to San Fran for Sales Trip",
    startDate: new Date(2017, 4, 22, 12, 0),
    endDate: new Date(2017, 4, 22, 13, 0),
    allDay: true
  }, {
    text: "Install New Router in Dev Room",
    startDate: new Date(2017, 4, 22, 14, 30),
    endDate: new Date(2017, 4, 22, 15, 30)
  }, {
    text: "Approve Personal Computer Upgrade Plan",
    startDate: new Date(2017, 4, 23, 10, 0),
    endDate: new Date(2017, 4, 23, 11, 0)
  }, {
    text: "Final Budget Review",
    startDate: new Date(2017, 4, 23, 12, 0),
    endDate: new Date(2017, 4, 23, 13, 35)
  }, {
    text: "New Brochures",
    startDate: new Date(2017, 4, 23, 14, 30),
    endDate: new Date(2017, 4, 23, 15, 45)
  }, {
    text: "Install New Database",
    startDate: new Date(2017, 4, 24, 9, 45),
    endDate: new Date(2017, 4, 24, 11, 15)
  }, {
    text: "Approve New Online Marketing Strategy",
    startDate: new Date(2017, 4, 24, 12, 0),
    endDate: new Date(2017, 4, 24, 14, 0)
  }, {
    text: "Upgrade Personal Computers",
    startDate: new Date(2017, 4, 24, 15, 15),
    endDate: new Date(2017, 4, 24, 16, 30)
  }, {
    text: "Customer Workshop",
    startDate: new Date(2017, 4, 25, 11, 0),
    endDate: new Date(2017, 4, 25, 12, 0),
    allDay: true
  }, {
    text: "Prepare 2015 Marketing Plan",
    startDate: new Date(2017, 4, 25, 11, 0),
    endDate: new Date(2017, 4, 25, 13, 30)
  }, {
    text: "Brochure Design Review",
    startDate: new Date(2017, 4, 25, 14, 0),
    endDate: new Date(2017, 4, 25, 15, 30)
  }, {
    text: "Create Icons for Website",
    startDate: new Date(2017, 4, 26, 10, 0),
    endDate: new Date(2017, 4, 26, 11, 30)
  }, {
    text: "Upgrade Server Hardware",
    startDate: new Date(2017, 4, 26, 14, 30),
    endDate: new Date(2017, 4, 26, 16, 0)
  }, {
    text: "Submit New Website Design",
    startDate: new Date(2017, 4, 26, 16, 30),
    endDate: new Date(2017, 4, 26, 18, 0)
  }, {
    text: "Launch New Website",
    startDate: new Date(2017, 4, 26, 12, 20),
    endDate: new Date(2017, 4, 26, 14, 0)
  }
];

export class Appointment {
  text: string;
  startDate: Date;
  endDate: Date;
  allDay?: boolean;
}



