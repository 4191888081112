import { MysqlService } from '../../services/mysql.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Component, OnInit, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { FilterByFileTypePipe } from '../../pipes/filter-by-file-type.pipe';
import { PlatformModule, Platform } from '@angular/cdk/platform';
import { IFile } from 'app/models/perizia.interface';

@Component({
  selector: 'app-doc-viewer',
  templateUrl: './doc-viewer.component.html',
  styleUrls: ['./doc-viewer.component.scss']
})
export class DocViewerComponent implements OnInit {

  _files = [];

  safe_url;
  safe_content;
  safe_url1;
  safe_content1;
  file_type: string;
  file_type1: string;
  platform: Platform;
  Browserbrutti;

  playing = false;

  @Input() filter: string[] = [];
  @Input() tag

  @Input() doubleView: boolean = false

  @Input() //files: any[];
  set files(files) {
    console.log('prev value: ', this._files);
    console.log('got name: ', files);


    if (this.tag != undefined)
      files = files.filter(e => ('' + this.tag) == ('' + e.tag))
    this._files = files;



    try {
      for (let i = 0; i < this._files.length; i++) {

        let s: string = this._files[i].fileName;
        this._files[i].ext = s.substr(-3).toLocaleLowerCase();
        /*
          if(this._files[i].ext!='adz') this._files[i].url = 'http://test.ggroup.cloud/api/expert/api.php?cmd=get_attachment&hash=' + this.files[i].hash + '&id=' + this.id + '&name=' + this.files[i].fileName;
          else this._files[i].url = this.db.get_print_adz_perizie(+this.id,false,this._files[i].hash)
        */

      }
    } catch (e) {
    }
  }

  @Input() descr;


  @Input() id: number[];
  @Input('index') i: number = 0;
  @Input() position: string = 'right';

  @Output() closed = new EventEmitter();

  @HostListener('document:keyup', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    console.log(event.key);

    switch (event.key.toLocaleLowerCase()) {

      case 'arrowleft':
        this.prev();
        break;

      case 'arrowright':
        this.next();
        break;

      case 'escape':
        this.close();
        break;

    }

  }

  constructor(private sanitizer: DomSanitizer, private db: MysqlService) { }

  ngOnInit() {

    console.log(this._files)

    this.safe_preview();

    this.playNext();
    if (this.getBrowserName() == 'edge' || this.getBrowserName() == 'safari') {
      this.Browserbrutti = true;
    } else this.Browserbrutti = false;
    console.log(this.Browserbrutti);
  }

  playNext() {
    return setTimeout(() => {
      if (this.playing) this.next();
      this.playNext();
    }, 2000);

  }

  timer;
  play() {
    this.playing = !this.playing;

  }


  prev() {

    if (this.i <= 0) this.i = this._files.length - 1;
    else this.i--;

    if (this.doubleView) {
      if (this.i <= 0) this.i = this._files.length - 1;
      else this.i--;
    }

    this.safe_preview();
  }

  next() {
    if (this.i >= this._files.length - 1) this.i = 0;
    else this.i++;

    if (this.doubleView) {
      if (this.i >= this._files.length - 1) this.i = 0;
      else this.i++;
    }




    this.safe_preview();
  }

  sanitize(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }


  close() {
    this.closed.emit();
  }

  safe_preview() {

    this.safe_url = null;
    this.safe_content = null;

    this.safe_url1 = null;
    this.safe_content1 = null;


    console.log('safe_preview: ', this.filter, this._files)

    let p = new FilterByFileTypePipe();
    let f = p.transform(this._files, this.filter);

    console.log('docviewer tag: ' + this.tag)




    this.file_type = this.fileType(f[this.i]);

    console.log('safe_preview: ' + this.i, f)

    try {
      f[this.i].fileContent ? this.safe_content = this.sanitize(f[this.i].fileContent) : this.safe_url = this.sanitize(f[this.i].url)
    } catch (error) {
      console.error(error)
    }


    if (this.doubleView) {
      this.file_type1 = this.fileType(f[this.i + 1]);
      f[this.i + 1].fileContent ? this.safe_content = this.sanitize(f[this.i + 1].fileContent) : this.safe_url1 = this.sanitize(f[this.i + 1].url)
    }

  }


  fileType(f: IFile): string {

    try {
      var ext = f.fileName.split('.').pop().toLocaleLowerCase();
    } catch (error) {
      return 'img';
    }


    switch (ext) {
      case 'pdf':
      case 'adz':
        ext = 'pdf';
        break;

      case 'png':
      case 'jpg':
      case 'jpeg':
      case 'tiff':
      case 'tif':
      case 'gif':
        ext = 'img';
        break;

      default:
        ext = 'oth';
        break;
    }

    console.log(ext);
    return ext;

  }


  public getBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase()
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }

}
